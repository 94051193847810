import './ControlListValueEdit.css';
import {
    Alert,
    Button,
    Container,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Spinner
} from "reactstrap";
import React, { useEffect, useId, useState } from "react";
import {
    useCreateControlListValueMutation,
    useGetConsumerControlListValuesQuery,
    useGetControlListActionsQuery,
    useGetControlListGroupsQuery,
    useGetControlListReasonsQuery, useUpdateControlListValueMutation,
} from "../../../../app/apiSlice";
import { ControlListValue } from "../ControlListValue";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ControlListValueLabel, ControlListValueType } from "../ControlListGroup";
import {RequiredAsterisk} from "../../../input/utils/RequiredAsterisk";
import {useClaims} from '../../../../app/hooks';
import { ADMIN, TEAM_LEAD } from '../../../../utils/Roles';
import { ControlListStringValueInput } from './ControlListStringValueInput';
import { ControlListComboInput } from './ControlListComboInput';
import { ControlListConsumerInput } from './ControlListConsumerInput';
import {ControlListGroupNames} from "./ControlListGroupNames";

interface ControlListValueEditProps {
    isOpen: boolean,
    onIsOpenChange: (newVal: boolean) => void,
    onValueSaved?: () => void,
    requestedControlListValue?: ControlListValue,
    consumerId?: number,
}
export const ControlListValueEdit = ({isOpen, onIsOpenChange, onValueSaved, requestedControlListValue, consumerId}: ControlListValueEditProps) => {
    const { hasPermission } = useClaims();
    const { data: controlListGroups } = useGetControlListGroupsQuery();
    const { data: controlListActions } = useGetControlListActionsQuery();
    const { data: controlListReasons } = useGetControlListReasonsQuery();
    const { data: consumerControlListValues } = useGetConsumerControlListValuesQuery(consumerId ?? skipToken);
    const [ updateControlListValue, {isLoading: isUpdating} ] = useUpdateControlListValueMutation();
    const [ createControlListValue, {isLoading: isCreating} ] = useCreateControlListValueMutation();
    
    const [ groupId, setGroupId ] = useState(requestedControlListValue?.controlListGroupId);
    const [ actionId, setActionId ] = useState(requestedControlListValue?.controlListActionId);
    const [ reasonId, setReasonId ] = useState(requestedControlListValue?.controlListReasonId);
    const [ stringValueOne, setStringValueOne ] = useState(requestedControlListValue?.stringValueOne ?? '');
    const [ stringValueTwo, setStringValueTwo ] = useState(requestedControlListValue?.stringValueTwo ?? '');
    const [ userId, setUserId ] = useState<number | undefined>(requestedControlListValue?.userId);
    const [ description, setDescription ] = useState(requestedControlListValue?.description ?? "");

    const [ errorMessage, setErrorMessage ] = useState('');
    const [ isClearingInput, setIsClearingInput ] = useState(false);

    const idOne = useId();
    const idTwo = useId();
    
    const isNew = requestedControlListValue?.id === undefined;
    const selectedGroup = controlListGroups?.find(clg => clg.id === groupId); 
    const inputTypeOne = selectedGroup?.valueOneType ?? ControlListValueType.None;
    const inputTypeTwo = selectedGroup?.valueTwoType ?? ControlListValueType.None;
    const labelOne = selectedGroup?.stringValueOneLabel ?? ControlListValueLabel.ValueOne;
    const labelTwo = selectedGroup?.stringValueTwoLabel ?? ControlListValueLabel.ValueTwo;

    const consumerOptions = consumerControlListValues?.consumerControlListGroupValues?.find(v => v.controlListGroup.id === groupId);
    const stringValueOneOptions = consumerOptions?.stringValueOneOptions;
    const stringValueTwoOptions = consumerOptions?.stringValueTwoOptions;
    const stringValueOneAndTwoComboOptions = consumerOptions?.stringValueOneAndTwoComboOptions;
    const addressValueOneOptions = consumerOptions?.addressValueOneOptions;
    const addressValueTwoOptions = consumerOptions?.addressValueTwoOptions;
    
    const isUsingRequestedGroup = groupId === requestedControlListValue?.controlListGroupId;
    const defaultStringValueOne = isUsingRequestedGroup ? requestedControlListValue?.stringValueOne : consumerOptions?.stringValueOne ?? stringValueOneOptions?.[0] ?? JSON.stringify(addressValueOneOptions?.[0]);
    const defaultStringValueTwo = isUsingRequestedGroup ? requestedControlListValue?.stringValueTwo : consumerOptions?.stringValueTwo ?? stringValueTwoOptions?.[0] ?? JSON.stringify(addressValueTwoOptions?.[0]);
    const defaultUserId = requestedControlListValue?.userId ?? consumerOptions?.consumer?.userId;
    const defaultDescription = requestedControlListValue?.description;
    const defaultActionId = requestedControlListValue?.controlListActionId ?? controlListActions?.[0]?.id;
    const defaultReasonId = requestedControlListValue?.controlListReasonId ?? controlListReasons?.[0]?.id;

    // lock the inputs if the choices we get back specify no-choice
    const stringValueOneDisabled = !!consumerOptions?.stringValueOne;
    const stringValueTwoDisabled = !!consumerOptions?.stringValueTwo;
    
    // simplify boolean display expressions
    const isCreatingOrUpdating = isCreating || isUpdating;
    const showStringValueInputOne = !stringValueOneAndTwoComboOptions?.length && (inputTypeOne === ControlListValueType.String || inputTypeOne === ControlListValueType.Address);
    const showConsumerInputOne = !stringValueOneAndTwoComboOptions?.length && inputTypeOne === ControlListValueType.UserId;
    const showStringValueInputTwo = !stringValueOneAndTwoComboOptions?.length && (inputTypeTwo === ControlListValueType.String || inputTypeTwo === ControlListValueType.Address);
    
    // reset string values on group change
    useEffect(() => {
        // don't clear inputs if we have (valid) default values to swap in
        if (!defaultStringValueOne && !defaultStringValueTwo) {
            setIsClearingInput(true);
        }
        setStringValueOne(defaultStringValueOne ?? '');
        setStringValueTwo(defaultStringValueTwo ?? '');
    }, [groupId, defaultStringValueOne, defaultStringValueTwo]);

    // turn isClearingInput back off when it is turned on
    useEffect(() => {
        const timeoutHandle = setTimeout(() => setIsClearingInput(false), 1_000);
        return () => clearTimeout(timeoutHandle);
    }, [isClearingInput]);

    // set group when groups load, or requested clv group changes
    useEffect(() => {
        setGroupId(old => requestedControlListValue?.controlListGroupId ?? old ?? controlListGroups?.[0]?.id)
    }, [controlListGroups, requestedControlListValue?.controlListGroupId]);

    // set default action when actions load
    useEffect(() => {
        setActionId(old => old ?? controlListActions?.[0]?.id)
    }, [controlListActions]);

    // set default reason when reasons load
    useEffect(() => {
        setReasonId(old => old ?? controlListReasons?.[0]?.id)
    }, [controlListReasons]);

    // clear errors/non clv state on close
    useEffect(() => {
        if (!isOpen) {
            setErrorMessage('');
            setIsClearingInput(true);
            setStringValueOne('');
            setStringValueTwo('');
            setUserId(undefined);
            setDescription('');
            return;
        }
        setStringValueOne(defaultStringValueOne ?? '');
        setStringValueTwo(defaultStringValueTwo ?? '');
        setUserId(defaultUserId);
        setReasonId(defaultReasonId);
        setActionId(defaultActionId);
        setDescription(defaultDescription ?? '');
    }, [isOpen, defaultStringValueOne, defaultStringValueTwo, defaultUserId, defaultReasonId, defaultActionId, defaultDescription]);

    const toggle = () => onIsOpenChange(!isOpen);
    

    const save = async () => {
        setErrorMessage('');
        
        const hasAllRequiredFields =
            groupId &&
            actionId &&
            reasonId &&
            description &&
            (!!stringValueOne || !!userId) &&
            (inputTypeTwo === ControlListValueType.None || !!stringValueTwo);

        if (!hasAllRequiredFields) {
            setErrorMessage('Please fill out all required fields.');
            return; // error state, missing information
        }
        
        const mutation = isNew ? createControlListValue : updateControlListValue;
        try {
            await mutation({
                controlListValueId: requestedControlListValue?.id,
                description,
                stringValueOne,
                stringValueTwo,
                controlListGroupId: groupId,
                controlListActionId: actionId,
                controlListReasonId: reasonId,
                userId,
            }).unwrap();
            onIsOpenChange(false);
            onValueSaved?.(); // alert parent to refresh the controllistvalue list
        } catch (e: any) {
            setErrorMessage(e.status === 401 ? 'Sorry, you do not have permission to do that.' : 'Error saving control list value! Please try again');
            console.error(e);
        }
    }
    
    const onComboSelect = (value1: string, value2: string) => {
        setStringValueOne(value1);
        setStringValueTwo(value2);
    }

    const showActions = () => {
        let selectedGroup = controlListGroups?.find(c => c.id === groupId);
        if (selectedGroup?.name === ControlListGroupNames.PHONE_NUMBER) {
            return (controlListActions?.filter(cla => cla.name === 'Blacklist')?.map(cla =>
                <option key={cla.id} value={cla.id}>
                    {cla.name}
                </option>
            ))
        } else {
            return (controlListActions?.filter(cla => cla.name !== 'Whitelist' || hasPermission([ADMIN, TEAM_LEAD]))?.map(cla =>
                <option key={cla.id} value={cla.id}>
                    {cla.name}
                </option>
            ))
        }
    }
    
    return (
        <Modal isOpen={isOpen} toggle={toggle} size='xl'>
            <ModalHeader toggle={toggle}>{isNew && 'New'} Control List Value</ModalHeader>
            <ModalBody>
                <Container>
                    <FormGroup>
                        <Label for='Group'>Group</Label>
                        <Input id='Group' name='group'  type="select" onChange={(e) => setGroupId(parseInt(e.target.value))} value={groupId}>
                            {controlListGroups?.map(clg => 
                                <option key={clg.id} value={clg.id}>
                                    {clg.name}
                                </option>
                            )}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label for='Action'>Action</Label>
                        <Input id='Action' name='action'  type="select" onChange={(e) => setActionId(parseInt(e.target.value))} value={actionId}>
                            {showActions()}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label for='Reason'>Reason</Label>
                        <Input id='Reason' name='reason'  type="select" onChange={(e) => setReasonId(parseInt(e.target.value))} value={reasonId}>
                            {controlListReasons?.map(clr => 
                                <option key={clr.id} value={clr.id}>
                                    {clr.name}
                                </option>
                            )}
                        </Input>
                    </FormGroup>

                    {/* Special Combo input to enter both value1 & value2 at the same time */}
                    {!!stringValueOneAndTwoComboOptions?.length &&
                        <FormGroup>
                            <Label for={idOne}>{labelOne} - {labelTwo} <RequiredAsterisk/></Label>
                            <ControlListComboInput id={idOne} value1={stringValueOne} value2={stringValueTwo} onChange={onComboSelect} options={stringValueOneAndTwoComboOptions} invalid={!!errorMessage && !(stringValueOne && stringValueTwo)}/>
                            <FormFeedback>Please select a value.</FormFeedback>
                        </FormGroup>
                    }
                    
                    {showStringValueInputOne &&
                        <FormGroup>
                            <Label for={idOne}>{labelOne} <RequiredAsterisk/></Label>
                                <ControlListStringValueInput id={idOne}
                                                             label={labelOne}
                                                             valueType={inputTypeOne}
                                                             value={stringValueOne}
                                                             onValueChange={setStringValueOne}
                                                             options={stringValueOneOptions}
                                                             addressOptions={addressValueOneOptions}
                                                             disabled={stringValueOneDisabled}
                                                             invalid={!!errorMessage && !stringValueOne}
                                                             clear={isClearingInput}
                                                             suppressError={!errorMessage}/>
                        </FormGroup>
                    }

                    {showConsumerInputOne &&
                        <FormGroup>
                            <Label for={idOne}>{labelOne}</Label> <RequiredAsterisk/>
                            <ControlListConsumerInput id={idOne} value={userId} onChange={setUserId} forceConsumer={consumerOptions?.consumer} invalid={!!errorMessage && !userId}/>
                            <FormFeedback>Please select a consumer.</FormFeedback>
                        </FormGroup>
                    }

                    {showStringValueInputTwo && 
                        <FormGroup>
                            <Label for={idTwo}>{selectedGroup?.stringValueTwoLabel} <RequiredAsterisk/></Label>
                            <ControlListStringValueInput id={idTwo}
                                                        label={labelTwo}
                                                        valueType={inputTypeTwo}
                                                        value={stringValueTwo}
                                                        onValueChange={setStringValueTwo}
                                                        options={stringValueTwoOptions}
                                                        addressOptions={addressValueTwoOptions}
                                                        disabled={stringValueTwoDisabled}
                                                        invalid={!!errorMessage && !stringValueTwo}
                                                        clear={isClearingInput}
                                                        suppressError={!errorMessage}/>
                        </FormGroup>
                    }

                    <FormGroup>
                        <Label for='Description'>Description <RequiredAsterisk/></Label>
                        <Input id='Description' name='description' type="text" onChange={(e) => setDescription(e.target.value)} value={description}
                            invalid={!!errorMessage && !description}
                        />
                        <FormFeedback>Required</FormFeedback>
                    </FormGroup>
                    { errorMessage &&
                        <Alert color='danger'>{errorMessage}</Alert>
                    }
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button color='primary' disabled={isCreatingOrUpdating} onClick={save}>
                    {isCreatingOrUpdating && <Spinner></Spinner>}
                    {isNew ? 'Create' : 'Update'}
                </Button>
                <Button onClick={() => onIsOpenChange(false)}>Close</Button>
            </ModalFooter>
        </Modal>
    );
}