import {useFormContext, useWatch} from "react-hook-form";
import {CheckoutSchema} from "../components/cart/checkout/schema";
import {useEffect} from "react";
import {AddressSchema, isSavedAddress} from "../addresses/AddressSchema";
import {
    getSelectedSavedShippingAddress,
    setSelectedSavedBillingAddress,
    setSelectedSavedPaymentDetails
} from "../components/order/CheckoutSlice";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useGetAddressesByConsumerIdQuery, useGetSavedPaymentDetailsQuery} from "../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {selectCurrentConsumerUser} from "../components/user/login/AuthenticationSlice";

export function useShouldUseShippingAddress() {

    const {setValue, getValues, control} = useFormContext<CheckoutSchema>();

    const savedShippingAddress = useAppSelector(getSelectedSavedShippingAddress);

    const shouldUseShippingAddress = useWatch({
        control,
        name: 'paymentInfo.useShippingAddress'
    });

    useEffect(() => {
        const shippingAddress = getValues('shippingInformation.shippingAddress');
        const addressToUse = isSavedAddress(shippingAddress)
            ? savedShippingAddress
            : shippingAddress;
        
        if (shouldUseShippingAddress) {
            setValue('paymentInfo.billingAddress', {
                id: -1,
                street: addressToUse?.street || undefined,
                city: addressToUse?.city || undefined,
                stateCode: addressToUse?.stateCode || undefined,
                zip: addressToUse?.zip || undefined
            });
        } else {
            setValue('paymentInfo.billingAddress', {
                id: -1,
                street: '',
                city: '',
                stateCode: '',
                zip: ''
            });
        }
    }, [shouldUseShippingAddress]);

}

export function useDispatchSavedBillingAddressSelected() {

    const dispatch = useAppDispatch();

    const consumer = useAppSelector(selectCurrentConsumerUser);

    const {data: savedAddresses = []} = useGetAddressesByConsumerIdQuery(consumer?.id ?? skipToken);

    const {watch, getValues} = useFormContext<CheckoutSchema>();

    let billingAddressId = watch('paymentInfo.billingAddress.id');

    useEffect(() => {

        const address = getValues('paymentInfo.billingAddress') as AddressSchema;

        if (!address) return;

        if (!isSavedAddress(address)) {
            dispatch(setSelectedSavedBillingAddress(null));
            return;
        }

        const savedAddress = savedAddresses.find(a => a.id == address.id);

        if (savedAddress) {
            dispatch(setSelectedSavedBillingAddress(savedAddress));
        }
    }, [savedAddresses, billingAddressId]);
}

export function useDispatchSavedPaymentMethodSelected() {
    const dispatch = useAppDispatch();

    const {data: savedPaymentMethods = []} = useGetSavedPaymentDetailsQuery()

    const {watch, getValues} = useFormContext<CheckoutSchema>();

    const watchedPaymentProfileId = watch('paymentInfo.paymentProfileId');
    useEffect(() => {
        const paymentProfileId = getValues('paymentInfo.paymentProfileId');

        if (paymentProfileId === '') {
            setSelectedSavedPaymentDetails(null);
        } else {
            const spm = savedPaymentMethods.find((savedPaymentMethod) => {
                return savedPaymentMethod.paymentProfileId === paymentProfileId;
            });
            if (spm) {
                dispatch(setSelectedSavedPaymentDetails(spm));
            }
        }
    }, [watchedPaymentProfileId]);
}