import {Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Col, Form, Row, Spinner} from "reactstrap";
import {ShippingInformationForm} from "../../order/ShippingInformationForm";
import {CheckoutSchema, checkoutSchema, shippingInformationSchema} from "./schema";
import {FieldPath, FormProvider, useForm, useWatch} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import React, {useEffect, useState} from "react";
import {ShippingAndHandlingFields} from "../../order/ShippingAndHandlingFields";
import {Address} from "../../input/address/Address";
import {PaymentInfoFields} from "../../order/PaymentInfoFields";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {isSavedAddress} from "../../../addresses/AddressSchema";
import {
    getSelectedSavedShippingAddress, setIsValid,
    setSelectedShippingOption,
    setValidatedShippingInformation
} from "../../order/CheckoutSlice";
import {selectCurrentConsumerUser} from "../../user/login/AuthenticationSlice";

type Panel = 'shipping-information' | 'shipping-options' | 'payment-info';

interface CheckoutFormProps {
    onSubmit(data: CheckoutSchema): void | Promise<void>;

    onShippingChanged?: (shipping: typeof checkoutSchema['shape']['shippingInformation']) => void;
}

export function CheckoutForm({
                                 onSubmit,
                                 onShippingChanged = () => void 0
                             }: CheckoutFormProps) {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const [toAddress, setToAddress] = useState<Address>({});
    const [currentPanel, setCurrentPanel] = useState<Panel>('shipping-information');

    const selectedSavedShippingAddress = useAppSelector(getSelectedSavedShippingAddress);
    const consumer = useAppSelector(selectCurrentConsumerUser);

    const form = useForm<CheckoutSchema>({
        resolver: zodResolver(checkoutSchema),
        mode: 'onTouched'
    });

    const {
        control,
        handleSubmit,
        formState,
        trigger,
        setValue
    } = form;

    const {
        isSubmitting
    } = formState;

    const shippingInfo = useWatch({
        control,
        name: 'shippingInformation'
    });

    useEffect(() => {
        if (!shippingInfo) {
            return;
        }
        const {shippingAddress} = shippingInfo || {};
        if (shippingAddress && !isSavedAddress(shippingAddress)) {
            setToAddress({
                street: shippingAddress.street,
                city: shippingAddress.city,
                stateCode: shippingAddress.stateCode,
                zip: shippingAddress.zip
            })
        } else if (selectedSavedShippingAddress) {
            setToAddress(selectedSavedShippingAddress);
        } else {
            setToAddress({});
        }
    }, [shippingInfo?.shippingAddress, selectedSavedShippingAddress]);

    useEffect(() => {
        dispatch(setSelectedShippingOption(null));
    }, []);

    useEffect(() => {
        if (consumer) {
            setValue('shippingInformation.firstName', consumer.firstName);
            setValue('shippingInformation.lastName', consumer.lastName);
            setValue('shippingInformation.phoneNumber', consumer.phoneNumber ?? '');

            setValue('emails', {
                email: consumer.email ?? '',
                repeatEmail: consumer.email ?? ''
            });
        }
    }, [consumer]);

    useEffect(() => {
        const {success, data} = shippingInformationSchema.safeParse(shippingInfo)

        if (success) {
            dispatch(setValidatedShippingInformation(data));
        } else {
            dispatch(setValidatedShippingInformation(null));
        }
    }, [shippingInfo]);

    const showPanelIfValid = (namesToCheck: FieldPath<CheckoutSchema>[], nextPanelToShow: Panel) => async () => {
        const allIsValid = await trigger(namesToCheck);
        if (allIsValid) {
            setCurrentPanel(nextPanelToShow);
        }
    };

    const doSubmit = async (checkout: CheckoutSchema) => {
        const valid = await trigger();
        dispatch(setIsValid(valid));
        if (valid) {
            await onSubmit(checkout);
        }
    }

    const toggle = async (id: string) => {
        if (currentPanel === 'shipping-information') {
            if (id === 'shipping-information') {
                // already on this panel
            } else if (id === 'shipping-options') {
                // validate then change
                let isValid = await trigger("shippingInformation");
                if (isValid) {
                    setCurrentPanel('shipping-options');
                }
            } else if (id === 'payment-info') {
                // do nothing, don't let user skip
            }
        } else if (currentPanel === 'shipping-options') {
            if (id === 'shipping-information') {
                // let user go back
                setCurrentPanel('shipping-information');
            } else if (id === 'shipping-options') {
                // already on this panel
            } else if (id === 'payment-info') {
                // validate then let user go
                let isValid = await trigger("shippingAndHandlingOption");
                if (isValid) {
                    setCurrentPanel('shipping-options');
                }
            }
        } else if (currentPanel === 'payment-info') {
            if (id === 'shipping-information') {
                // let user go back
                setCurrentPanel('shipping-information');
            } else if (id === 'shipping-options') {
                // let user go back
                setCurrentPanel('shipping-options');
            } else if (id === 'payment-info') {
                // already on this panel
            }
        }
    };

    return (
        <FormProvider {...form}>
            <Form onSubmit={handleSubmit(doSubmit)}>
                <Accordion open={currentPanel} toggle={toggle}>
                    <AccordionItem>
                        <AccordionHeader targetId={'shipping-information'}>
                            Shipping Information
                        </AccordionHeader>
                        <AccordionBody accordionId={'shipping-information'}>
                            <ShippingInformationForm/>
                            <CheckoutFormPanelButtons
                                onContinue={showPanelIfValid(["shippingInformation", "emails"], 'shipping-options')}/>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId={'shipping-options'}>
                            Shipping Method
                        </AccordionHeader>
                        <AccordionBody accordionId={'shipping-options'}>
                            <ShippingAndHandlingFields toAddress={toAddress}/>
                            <CheckoutFormPanelButtons
                                className="mt-4"
                                onContinue={showPanelIfValid(["shippingAndHandlingOption"], 'payment-info')}/>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="payment-info">
                            Payment Info
                        </AccordionHeader>
                        <AccordionBody accordionId="payment-info">
                            <PaymentInfoFields/>
                            <Row className="mt-4">
                                <Col className="cart-checkout-payment-disclaimer">
                                    {t(`cart.paymentDisclaimer`)}
                                </Col>
                                <Col>
                                    <Button color="primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="cart-checkout-proceed-button">
                                        Place Order
                                        {isSubmitting &&
                                            <Spinner/>}
                                    </Button>
                                </Col>
                            </Row>
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </Form>
        </FormProvider>
    );
}

interface CheckoutFormPanelButtonsProps {
    onContinue: () => Promise<void> | void;
    className?: string;
}

function CheckoutFormPanelButtons({
                                      onContinue,
                                      className = ''
                                  }: CheckoutFormPanelButtonsProps) {
    return (
        <div className={`d-flex justify-content-end ${className}`}>
            <Button onClick={onContinue}
                    color="primary">
                Continue
            </Button>
        </div>
    );
}