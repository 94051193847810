import {useTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";
import {useFormContext, useWatch} from "react-hook-form";
import {useEffect, useState} from "react";
import {FormInputField} from "../forms/FormInputField";
import {CheckoutSchema} from "../cart/checkout/schema";
import {FormAddressInput} from "../../addresses/FormAddressInput";
import {FormExpirationDateField} from "../../payment/FormExpirationDateField";
import {FormCreditCardInput} from "../../payment/FormCreditCardInput";
import {SavedPaymentMethodSelector} from "../../payment/SavedPaymentMethodSelector";
import {useGetSavedPaymentDetailsQuery} from "../../app/apiSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectCurrentConsumerUser} from "../user/login/AuthenticationSlice";
import {
    useDispatchSavedBillingAddressSelected,
    useDispatchSavedPaymentMethodSelected,
    useShouldUseShippingAddress
} from "../../checkout/hooks";
import {setBillingAddressIsVerified} from "./CheckoutSlice";
import {SavedPaymentDetails} from "../payment/SavedPaymentDetails";

const DEFAULT_SAVED_PAYMENT_METHODS: SavedPaymentDetails[] = [];

export function PaymentInfoFields() {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const [showDetails, setShowDetails] = useState(true);

    const consumer = useAppSelector(selectCurrentConsumerUser);
    const {data: savedPaymentMethods = DEFAULT_SAVED_PAYMENT_METHODS} = useGetSavedPaymentDetailsQuery();

    const {
        control,
        setValue,
        watch,
    } = useFormContext<CheckoutSchema>();

    // Currently this is only used on the consumer side and only supports CC
    // payment method id is from the database
    // payment details type came from <CreditCardDetailsEntry />
    useEffect(() => {
        if (savedPaymentMethods && savedPaymentMethods.length) {
            let savedPaymentMethod = savedPaymentMethods?.find(spd => spd.paymentProfileId);
            if (savedPaymentMethod) {
                if (watchedPaymentProfileId !== savedPaymentMethod.paymentProfileId) {
                    setValue('paymentInfo.paymentProfileId', savedPaymentMethod?.paymentProfileId ?? '');
                }
            }
        } else {
            if (watchedPaymentProfileId !== '') {
                setValue('paymentInfo.paymentProfileId', '');
            }
        }
    }, [savedPaymentMethods]);

    const watchedPaymentProfileId = watch('paymentInfo.paymentProfileId');
    useEffect(() => {
        if (watchedPaymentProfileId === '') {
            setShowDetails(true);
        } else {
            setShowDetails(false);
        }
    }, [watchedPaymentProfileId]);

    useDispatchSavedPaymentMethodSelected();
    useDispatchSavedBillingAddressSelected();
    useShouldUseShippingAddress();

    const shouldUseShippingAddress = useWatch({
        control,
        name: 'paymentInfo.useShippingAddress'
    });
    
    function handleVerificationComplete(verified: boolean) {
        dispatch(setBillingAddressIsVerified(verified));
    }


    return (
        <>
            <p className="bolded-text">{t('payment.message')}</p>
            {!!consumer && <Row>
                <SavedPaymentMethodSelector control={control}
                                            name="paymentInfo.paymentProfileId"
                                            label="Saved Payment Method"
                />
            </Row>}
            {showDetails &&
                <>
                    <Row>
                        <FormInputField control={control}
                                        name="paymentInfo.firstName"
                                        label="First Name"
                                        className="w-50"
                                        requiredAsterisk
                                        autoComplete="cc-given-name"
                        />
                    </Row>
                    <Row>
                        <FormInputField control={control}
                                        name="paymentInfo.lastName"
                                        label="Last Name"
                                        className="w-50"
                                        requiredAsterisk
                                        autoComplete="cc-family-name"
                        />
                    </Row>
                    <Row>
                        <FormCreditCardInput control={control}
                                             name="paymentInfo.cardNumber"
                                             label="Credit Card Number"
                                             autoComplete="cc-number"
                                             requiredAsterisk
                        />
                    </Row>
                    <Row>
                        <Col>
                            <FormExpirationDateField control={control}
                                                     name="paymentInfo.expirationDate"
                                                     label="Expiration"
                                                     requiredAsterisk
                            />
                        </Col>
                        <Col>
                            <FormInputField control={control}
                                            name="paymentInfo.cvc"
                                            label="CVC"
                                            requiredAsterisk
                                            autoComplete="cc-cvc"
                            />
                        </Col>
                    </Row>
                    {!!consumer &&
                        <Row>
                            <Col>
                                <FormInputField control={control}
                                                name="paymentInfo.savePaymentMethod"
                                                label="Save payment method"
                                                type="checkbox"
                                                defaultChecked={true}
                                />
                            </Col>
                        </Row>
                    }
                    <Row>
                        <FormAddressInput control={control}
                                          name="paymentInfo.billingAddress"
                                          label="Billing Address"
                                          section="billing"
                                          onVerificationComplete={handleVerificationComplete}
                                          readOnly={!!shouldUseShippingAddress}
                                          before={
                                              <FormInputField control={control}
                                                              name="paymentInfo.useShippingAddress"
                                                              label="Use shipping address"
                                                              type="checkbox"
                                              />
                                          }
                        />
                    </Row>
                </>}
        </>
    );
}
