import { configureStore } from '@reduxjs/toolkit';
import siteCardReducer from '../components/siteCard/SiteCardSlice';
import navigationReducer from '../components/navigation/NavigationSlice';
import productInputReducer from '../components/input/ProductInputSlice';
import modalReducer from '../components/modal/ModalSlice';
import orderReducer from '../components/order/OrderSlice';
import fiReducer from '../components/admin/financialInstitution/FinancialInstitutionSlice';
import consumerReducer from '../components/admin/customer/ConsumerSlice';
import holdFlagReducer from '../components/admin/holdsQueue/HoldFlagSlice';
import controlListValueReducer from '../components/admin/controlList/ControlListValueSlice';
import cartReducer from '../components/cart/CartSlice';
import editOrderReducer from '../components/order/EditOrderSlice';
import  authReducer from '../components/user/login/AuthenticationSlice';
import { apiSlice } from "./apiSlice";
import checkoutReducer from '../components/order/CheckoutSlice';

export const store = configureStore({
  reducer: {
    siteCards: siteCardReducer,
    cardItems: siteCardReducer,
    navigation: navigationReducer,
    productInputs: productInputReducer,
    orders: orderReducer,
    financialInstitutions: fiReducer,
    consumers: consumerReducer,
    holdFlags: holdFlagReducer,
    controlListValues: controlListValueReducer,
    cart: cartReducer,
    editOrder: editOrderReducer,
    modal: modalReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    authentication: authReducer,
    checkout: checkoutReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // modal uses functions & components in store
        ignoredActions: ['modal/showConfirmationModal', 'modal/showInformationModal'],
        ignoredPaths: ['modal'],
      }
    }).concat(apiSlice.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;