import './CartOverview.css';
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {CartProductVM} from "../CartProduct";
import {CartPricing, SiteProductVariantPricing} from "../CartPricing";
import {OrderSummaryFields} from "../../order/OrderSummaryFields";

interface ProductPreviewProps {
    cartProducts: CartProductVM[],
    cartPricing?: CartPricing,
    shouldShowShipping: boolean
}

export const CartOverview = (props: ProductPreviewProps) => {

    return (
        <div className="cart-overview-container" >

            <OrderSummaryFields {...props} />

        </div>
    );
}

//<FreeShippingLabel />