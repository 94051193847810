import {FormSelect, FormSelectProps} from "../components/forms/FormSelect";
import {FieldValues} from "react-hook-form";
import {useGetSavedPaymentDetailsQuery} from "../app/apiSlice";

interface SavedPaymentMethodSelectorProps<Schema extends FieldValues>
    extends Omit<FormSelectProps<Schema>, 'options'> {

}

export function SavedPaymentMethodSelector<Schema extends FieldValues>(props: SavedPaymentMethodSelectorProps<Schema>) {

    const {data: savedPaymentMethods = []} = useGetSavedPaymentDetailsQuery()

    const options = [
        {
            text: 'New Payment Method',
            value: ''
        },
        ...savedPaymentMethods.map(spm => ({
            text: `${spm.cardNumber} - ${spm.expirationDateString}`,
            value: spm.paymentProfileId
        }))
    ]

    return (
        <FormSelect {...props}
                    options={options}
        />
    );
}