import * as z from "zod";
import {fallbackRefinement} from "../validation/FallbackRefinement";
import {Address} from "../components/input/address/Address";

const savedAddressSchema = z.object({
    id: z.coerce.number().default(-1),
}).passthrough();

const newAddressSchema = z.object({
    // id: z.coerce.number(),
    street: z.string()
        .min(3),
    city: z.string()
        .min(3),
    stateCode: z.string()
        .length(2),
    zip: z.string()
        .min(5)
        .max(10),
});

export const addressSchema = savedAddressSchema
    .or(newAddressSchema)
    .superRefine(
        fallbackRefinement(
            (val: unknown) => !isSavedAddress(val as AddressSchema),
            newAddressSchema
        )
    )
;


export type AddressSchema = z.infer<typeof addressSchema>;

export function isSavedAddress(address: AddressSchema | undefined): address is z.infer<typeof savedAddressSchema> {
    return !!address && 'id' in address && address.id > 0;
}

type AddressNames = {
    readonly first: string;
    readonly last: string;
};

export function toAddress(address: AddressSchema, 
                          {first, last}: AddressNames, 
                          fallback: Address | null,
                          isVerified = false): Address {
    
    if (isSavedAddress(address)) {
        if (fallback === null) {
            throw Error('A fallback address must be provided for saved addresses');
        }
        return {
            ...fallback,
            // saved address to not save verification status
            isVerified: fallback?.isVerified ?? isVerified,
            name: first,
            nameTwo: last
        };
    }
    return {
        ...address,
        isVerified,
        name: first,
        nameTwo: last,
    }
}