import {Address} from "../components/input/address/Address";
import classnames from "classnames";

interface AddressLabelProps {
    address: Readonly<Address>;
    readonly className?: string;
}

export default function AddressLabel({
                                         address,
                                         className
                                     }: Readonly<AddressLabelProps>) {
    
    const classes = classnames(
        'address-label',
        'border',
        'border-dark-subtle',
        'mb-4',
        'p-3',
        className
    );
    
    return (
        <address className={classes}>
            <div>{address.name} {address.nameTwo}</div>
            <div>{address.street}</div>
            <div>{address.city}, {address.stateCode} {address.zip}</div>
        </address>
    );
}