import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SavedPaymentDetails} from "../payment/SavedPaymentDetails";
import {RootState} from "../../app/store";
import {Address} from "../input/address/Address";
import {ShippingOption} from "../shipping-options/ShippingOption";
import {ShippingInformationSchema} from "../cart/checkout/schema";

type OrNull<Type> = Type | null;

type CheckoutState = {
    selectedSavedPaymentDetails: SavedPaymentDetails | null;
    selectedSavedShippingAddress: Address | null;
    selectedSavedBillingAddress: Address | null;
    selectedShippingOption: ShippingOption | null;
    validatedShippingInformation: OrNull<ShippingInformationSchema>;
    isValid: boolean;
    shippingAddressIsVerified: boolean;
    billingAddressIsVerified: boolean;
};

const initialState: CheckoutState = {
    selectedSavedPaymentDetails: null,
    selectedSavedShippingAddress: null,
    selectedSavedBillingAddress: null,
    selectedShippingOption: null,
    validatedShippingInformation: null,
    isValid: true,
    shippingAddressIsVerified: false,
    billingAddressIsVerified: false
}

const slice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setSelectedSavedPaymentDetails: (state, {payload}: PayloadAction<OrNull<SavedPaymentDetails>>) => {
            state.selectedSavedPaymentDetails = payload;
        },
        setSelectedSavedShippingAddress: (state, {payload}: PayloadAction<OrNull<Address>>) => {
            state.selectedSavedShippingAddress = payload;
        },
        setSelectedSavedBillingAddress: (state, {payload}: PayloadAction<OrNull<Address>>) => {
            state.selectedSavedBillingAddress = payload;
        },
        setSelectedShippingOption: (state, {payload}: PayloadAction<OrNull<ShippingOption>>) => {
            state.selectedShippingOption = payload
        },
        setValidatedShippingInformation: (state, {payload}: PayloadAction<OrNull<ShippingInformationSchema>>) => {
            state.validatedShippingInformation = payload;
        },
        setIsValid: (state, {payload}: PayloadAction<boolean>) => {
            state.isValid = payload;
        },
        setShippingAddressIsVerified: (state, {payload}: PayloadAction<boolean>) => {
            state.shippingAddressIsVerified = payload;
        },
        setBillingAddressIsVerified: (state, {payload}: PayloadAction<boolean>) => {
            state.billingAddressIsVerified = payload;
        }
    }
});

export const getSelectedSavedPaymentDetails = (state: RootState) => {
    return state.checkout.selectedSavedPaymentDetails;
};
export const getSelectedSavedShippingAddress = (state: RootState) =>
    state.checkout.selectedSavedShippingAddress;

export const getSelectedSavedBillingAddress = (state: RootState) =>
    state.checkout.selectedSavedBillingAddress;

export const getSelectedShippingOption = (state: RootState) =>
    state.checkout.selectedShippingOption;

export const getCheckoutIsValid = (state: RootState) => 
    state.checkout.isValid;

export const getShippingInformation = (state: RootState) => 
    state.checkout.validatedShippingInformation;

export const getShippingAddressIsVerified = (state: RootState) =>
    state.checkout.shippingAddressIsVerified;

export const getBillingAddressIsVerified = (state: RootState) => 
    state.checkout.billingAddressIsVerified;

const {actions, reducer} = slice;

export default reducer;
export const {
    setSelectedSavedPaymentDetails,
    setSelectedSavedShippingAddress,
    setSelectedSavedBillingAddress,
    setSelectedShippingOption,
    setValidatedShippingInformation,
    setIsValid,
    setShippingAddressIsVerified,
    setBillingAddressIsVerified
} = actions