import {curry} from "lodash";
import {FieldValues} from "react-hook-form";
import * as z from "zod";

export const fallbackRefinement = curry(
    <
        Schema extends FieldValues
    >(
        predicate: (val: Schema) => boolean,
        schema: z.ZodSchema,
        arg: Schema,
        ctx: z.RefinementCtx
    ) => {

        if (predicate(arg)) {
            const {success, error} = schema.safeParse(arg);
            if (!success && error) {
                for (let i = 0; i < error.issues.length; i++) {
                    ctx.addIssue(error.issues[i]);
                }
            }
        }
    });