import {FieldValues} from "react-hook-form";
import {FormInputField, FormInputProps} from "../components/forms/FormInputField";
import classnames from "classnames";
import {FULL_STORY_SECURE_ELEMENT_CLASS} from "../components/input/ProductInput";
import {ChangeEvent} from "react";

export function FormCreditCardInput<Schema extends FieldValues>(props: Omit<FormInputProps<Schema>, 'placeholder' | 'onChange'>) {
    const {
        className,
        ...rest
    } = props;

    const inputClassNames = classnames(
        FULL_STORY_SECURE_ELEMENT_CLASS,
        className
    );

    const handleCcNumberChange = (value: string) =>
        formatCreditCardNumber(value);

    return (
        <FormInputField {...rest}
                        placeholder="xxxx xxxx xxxx xxxx"
                        className={inputClassNames}
                        onChange={handleCcNumberChange}
        />

    );
}

function formatCreditCardNumber(cardNumber: string = '') {
    cardNumber = cardNumber ? cardNumber.replace(/[^0-9 ]/ig, "") : cardNumber;
    if (cardNumber?.includes(' ')) {
        cardNumber = cardNumber.replaceAll(' ', '');
    }

    const pattern = cardNumber?.startsWith('34') || cardNumber?.startsWith('37') ?
        '#### ###### #####' : // AMEX
        '#### #### #### ####'; // others

    let i = 0;
    return pattern.replace(/#/g, _ => (cardNumber ?? '')[i++] ?? '').trim();
}


