import { Button, Card, CardBody, CardHeader, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import {formatPhoneNumber, phoneNumberInputMaxLength, Utils} from "../../../utils/Utils";
import { ConsumerAddressInput } from "../../input/address/ConsumerAddressInput";
import { Address, DefaultAddressType } from "../../input/address/Address";
import { RequiredAsterisk } from "../../input/utils/RequiredAsterisk";
import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react";
import { ValidationHook } from "../../../app/hooks";
import "./ShippingInformation.css";
import { useGetConsumerDetailsByIdQuery } from "../../../app/apiSlice";
import { EmailInput } from "../../input/email/EmailInput";
import {skipToken} from "@reduxjs/toolkit/query";
import {isUsingAdminSite} from "../../../utils/SiteHelper";

export class ShippingInformation {
  firstName?: string
  lastName?: string
  email?: string
  repeatEmail?: string
  phoneNumber?: string
  address?: Address
}

interface ShippingInformationProps {
  shipping?: ShippingInformation,
  onShippingChanged: (shipping: ShippingInformation) => void,
  toggleIsEditingShipping?: () => void,
  createCartFromOrder?: () => void,

  consumerId?: number,
  onFillFromCheck?: () => void,
  locked?: boolean,
  validation?: ValidationHook,
  readOnlyDefault?: boolean,
  suppressError?: boolean,
}
export const ShippingInformationCard = ({ shipping, onShippingChanged, toggleIsEditingShipping,createCartFromOrder, consumerId, onFillFromCheck,locked, validation, readOnlyDefault, suppressError } : ShippingInformationProps) => {
  const { data: consumer, isLoading: loadingConsumer } = useGetConsumerDetailsByIdQuery(consumerId ?? skipToken);
  // this makes the handleChanges work the state being contained outside of this component
  const handle = Utils.handleChanges((setter: any) => onShippingChanged(setter(shipping)));
  const [ isReadOnly, setIsReadOnly ] = useState(readOnlyDefault ?? false);
  const isAdminSite = isUsingAdminSite();

  useEffect(() => {
    setIsReadOnly(readOnlyDefault ?? false);
  }, [readOnlyDefault]);
  
  useEffect(() => {
    const emailValue = shipping?.email || "";
    const repeatEmailValue = shipping?.repeatEmail || "";
  }, [shipping?.email, shipping?.repeatEmail]);

  const validateAndHandle = (validationKey: string, e: ChangeEvent<HTMLInputElement>) => {
    validation?.setValue(validationKey, e.target.value);
    handle(e);
  }

  const handleAddressChange = (address?: Address) => {
    onShippingChanged({
      ...shipping,
      address: {
        ...address,
        name: `${shipping?.firstName} ${shipping?.lastName}`
      },
    })
  }

  const onFirstNameChange = (firstName?: string) => {
    validation?.setValue('firstName', firstName);
    const name = `${firstName} ${shipping?.lastName}`
    onShippingChanged({...shipping, address: {...shipping?.address, name}, firstName: firstName});
  }

  const onLastNameChange = (lastName?: string) => {
    validation?.setValue('lastName', lastName);
    const name = `${shipping?.firstName} ${lastName}`;
    onShippingChanged({...shipping, address: {...shipping?.address, name}, lastName: lastName});
  }

  const onPhoneNumberChange = (phoneNumber?: string) => {
    const phoneNumberValue = formatPhoneNumber(phoneNumber);
    validation?.setValue('phoneNumber', phoneNumber);
    onShippingChanged({...shipping, phoneNumber: phoneNumberValue});
  }

  const toggleIsReadOnly = () => {
    createCartFromOrder?.();
    setIsReadOnly(!isReadOnly);
    toggleIsEditingShipping?.();
  }
  
  return (
      <Card id="shipping-information-card">
        <CardHeader className='d-flex justify-content-between card-header'>
          <h5 className="shipping-information-title m-0">Shipping Information</h5>
          { !locked && isReadOnly ? <Button color='primary' onClick={() => toggleIsReadOnly()}>Edit</Button> : <></> }
          {onFillFromCheck !== undefined && <Button color='primary' onClick={onFillFromCheck}>Fill from Check</Button> }
        </CardHeader>
        <CardBody>
          {/*Name*/}
          <Row className="shipping-information-row">
            <Col>
              <FormGroup>
                <Label for='ShippingFirstName'>First Name <RequiredAsterisk/></Label>
                <Input id='ShippingFirstName'
                       autoComplete={isAdminSite || shipping?.firstName ? "autocomplete_off" : "given-name"}
                       required
                       name='firstName'
                       maxLength={100}
                       disabled={(locked ?? false) || isReadOnly}
                       value={shipping?.firstName ?? consumer?.firstName ?? ''}
                       invalid={validation?.getError('firstName') !== undefined}
                       onChange={e => onFirstNameChange(e.target.value)}/>
                <FormFeedback>{validation?.getError('firstName')}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for='ShippingLastName'>Last Name <RequiredAsterisk/></Label>
                <Input id='ShippingLastName'
                       autoComplete={isAdminSite || shipping?.lastName ? "autocomplete_off" : "family-name"}
                       required
                       name='lastName'
                       maxLength={100}
                       disabled={(locked ?? false) || isReadOnly}
                       value={shipping?.lastName ?? consumer?.lastName ?? ''}
                       invalid={validation?.getError('lastName') !== undefined}
                       onChange={e => onLastNameChange(e.target.value)}/>
                <FormFeedback>{validation?.getError('lastName')}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

        {/*Contact Info*/}
        <Row className="shipping-information-row">
          <Col>
            <FormGroup>
              <Label for='ShippingEmail'>Email <RequiredAsterisk/></Label>
              <EmailInput id='ShippingEmail' type='email' name='email'
                     autoComplete={isAdminSite || shipping?.email ? "autocomplete_off" : "email"}
                     required
                     disabled={(locked ?? false) || isReadOnly}
                     value={shipping?.email ?? consumer?.email ?? ''}
                     invalid={validation?.getError('email') !== undefined}
                     suppressError={!shipping?.email}
                     errorText={validation?.getError('email')}
                     onChange={e => validateAndHandle('email', e)}/>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for='ShippingRepeatEmail'>Re-enter Email <RequiredAsterisk/></Label>
              <EmailInput id='ShippingRepeatEmail' type='email' name='repeatEmail'
                          autoComplete={"autocomplete_off"}
                          required
                          disabled={(locked ?? false) || isReadOnly}
                          value={shipping?.repeatEmail ?? consumer?.email ?? ''}
                          invalid={validation?.getError('repeatEmail') !== undefined}
                          suppressError={!shipping?.repeatEmail}
                          errorText={validation?.getError('repeatEmail')}
                          onChange={e => validateAndHandle('repeatEmail', e)}
                          onPaste={(e) => e.preventDefault()}
                          onDrop={(e) => e.preventDefault()}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label for='ShippingPhoneNumber'>Phone Number <RequiredAsterisk/></Label>
              <Input id='ShippingPhoneNumber'
                     autoComplete={isAdminSite || shipping?.phoneNumber ? "autocomplete_off" : "tel-national"}
                     placeholder='(   ) ___-____'
                     name='phoneNumber'
                     disabled={(locked ?? false) || isReadOnly}
                     value={shipping?.phoneNumber ?? ''}
                     required
                     maxLength={phoneNumberInputMaxLength}
                     invalid={validation?.getError('phoneNumber') !== undefined}
                     onChange={(e) => onPhoneNumberChange(e.target.value)}/>
              <FormFeedback>{validation?.getError('phoneNumber')}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        
        <Row className='mt-3'>
          {/*Other Address*/}
          <ConsumerAddressInput address={shipping?.address}
                             onAddressChanged={handleAddressChange}
                             consumerId={consumerId}
                             defaultAddress={DefaultAddressType.Shipping}
                             locked={locked}
                             isReadOnly={isReadOnly}
                             isShippingAddress={true}
                             suppressError={suppressError}
          />
        </Row>
      </CardBody>
    </Card>
  );
}

const EmailMismatchMessage = ({ show }: { show: boolean }) => {
  if (!show) return null;
  return (
      <Row>
        <Col>
          <p className={'error-text'}><RequiredAsterisk /> Email addresses do not match. Please enter matching email addresses.</p>
        </Col>
      </Row>
  );
};
