import * as z from 'zod';
import {Regexes} from "../../../utils/Utils";
import {addressSchema} from "../../../addresses/AddressSchema";
import {paymentDetailsSchema} from "../../../payment/schema";

export const shippingInformationSchema = z.object({
    firstName: z.string().min(3),
    lastName: z.string().min(3),
    phoneNumber: z.string()
        .length(12, 'Please enter a valid phone number'),
    shippingAddress: addressSchema,
    useCheckAddress: z.coerce.boolean()
        .default(false)
});
export type ShippingInformationSchema = z.infer<typeof shippingInformationSchema>;

export const checkoutSchema = z.object({
    shippingInformation: shippingInformationSchema,
    emails: z.object({
        email: z.string().email(),
        repeatEmail: z.string().email(),
    }).refine((data) => {
        return data.email.toLowerCase() === data.repeatEmail.toLowerCase();
    }, {
        message: "Email addresses must match",
        path: ["repeatEmail"],
    }),
    shippingAndHandlingOption: z.coerce.number(),
    paymentInfo: paymentDetailsSchema
});

export type CheckoutSchema = z.infer<typeof checkoutSchema>;