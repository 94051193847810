import {FieldValues} from "react-hook-form";
import {FormInputField, FormInputProps} from "./FormInputField";
import {FormGroup, Label} from "reactstrap";

type Option = { text: string, value: string | number };

export interface FormSelectProps<Schema extends FieldValues> extends Omit<FormInputProps<Schema>, 'type'> {
    readonly options: ReadonlyArray<Option> | undefined;
}

export function FormSelect<Schema extends FieldValues>(props: FormSelectProps<Schema>) {

    const {
        options = [],
        ...rest
    } = props;
    return (
        <FormGroup>
            <FormInputField {...rest} type="select">
                {options.map(o => (
                    <option value={o.value}>{o.text}</option>
                ))}
            </FormInputField>
        </FormGroup>
    );
}