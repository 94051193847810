import {FieldValues, useFormContext} from "react-hook-form";
import {Button, Col, Label, Row} from "reactstrap";
import React, {ReactElement, useEffect, useState} from "react";
import styles from '../components/input/validatedAddress/ValidatedAddressInput.module.css';
import {at} from "lodash";
import {ConsumerAddressInput} from "./ConsumerAddressInput";
import {FormInputField, FormInputProps} from "../components/forms/FormInputField";
import {AddressInputVm} from "../components/input/address/Address";
import {useAppSelector, useSmartyStreets} from "../app/hooks";
import {selectCurrentConsumerUser} from "../components/user/login/AuthenticationSlice";
import {AddressSchema, isSavedAddress} from "./AddressSchema";
import {useGetAddressesByConsumerIdQuery} from "../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import classnames from 'classnames';
import {FormStateDropdown} from "./FormStateDropdown";
import AddressLabel from "./AddressLabel";
import cn from "classnames";
import {setShippingAddressIsVerified} from "../components/order/CheckoutSlice";

interface FormAddressInputProps<Schema extends FieldValues>
    extends Omit<FormInputProps<Schema>, 'type' | 'placeholder'> {

    readonly section?: 'billing' | 'shipping' | string;
    readonly before?: ReactElement;
    readonly onVerificationComplete?: (verified: boolean) => void | Promise<void>
}

export function FormAddressInput<Schema extends FieldValues>(
    {
        name,
        label,
        before,
        readOnly,
        section = '',
        onVerificationComplete = verified => void 0
    }: FormAddressInputProps<Schema>) {

    const [showAddressFields, setShowAddressFields] = useState(true);
    const [searchAddress, setSearchAddress] = useState<AddressInputVm>({});
    const [verifyAddress, setVerifyAddress] = useState<AddressInputVm>({});

    const consumer = useAppSelector(selectCurrentConsumerUser);
    const {data: consumerAddresses = []} = useGetAddressesByConsumerIdQuery(consumer?.id ?? skipToken)

    const {matches: suggestedAddresses} = useSmartyStreets(searchAddress);
    const {isVerified} = useSmartyStreets(verifyAddress);

    const {
        watch,
        setValue: setFormValue,
        reset,
    } = useFormContext();

    useEffect(() => {
        const subscription = watch((form, {name: changedFieldName, type}) => {
            // If the changed field is "street" and the change was made via user input (type === "change")
            const [value] = at(form, name) as [AddressSchema];

            if (!changedFieldName?.startsWith(name)) return;

            if (isSavedAddress(value)) {
                return;
            }

            if (changedFieldName === `${name}.street`) {
                setSearchAddress({
                    street: value?.street
                });
                setVerifyAddress({
                    ...verifyAddress,
                    street: value?.street
                });
            }

            // If the changed field is "city" and the change was made via user input (type === "change")
            if (changedFieldName === `${name}.city`) {
                setSearchAddress({});
                setVerifyAddress({
                    ...verifyAddress,
                    city: value?.city
                });
            }

            // If the changed field is "state" and the change was made via user input (type === "change")
            if (changedFieldName === `${name}.stateCode`) {
                setSearchAddress({});
                setVerifyAddress({
                    ...verifyAddress,
                    stateCode: value?.stateCode
                });
            }

            // If the changed field is "zip" and the change was made via user input (type === "change")
            if (changedFieldName === `${name}.zip`) {
                setSearchAddress({});
                setVerifyAddress({
                    ...verifyAddress,
                    zip: value?.zip
                });
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, verifyAddress]);

    useEffect(() => {
        onVerificationComplete(isVerified);    
    }, [isVerified]);
    
    const setValue: typeof setFormValue = (childName, value, options) =>
        setFormValue(`${name}.${childName}`, value, options);

    const savedAddressId = watch(`${name}.id`);

    useEffect(() => {
        if (savedAddressId !== undefined && savedAddressId != -1) {
            setShowAddressFields(!consumer)
            setAddressFields({
                street: '',
                city: '',
                stateCode: '',
                zip: ''
            }, false);
            const savedAddress = consumerAddresses
                .find(a => a?.id == savedAddressId);
            if (savedAddress) {
                setVerifyAddress(savedAddress);
            }
        } else {
            setShowAddressFields(true);
        }
    }, [savedAddressId, consumer]);
    
    const value = watch(name);
    
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
    const getAutoComplete = (value: string) =>
        (section !== 'shipping' && section !== 'billing')
            ? `section-${section} ${value}`
            : `${section} ${value}`;

    const setAddressFields = (addr: AddressInputVm, shouldMess = true) => {
        const setValueOptions = {
            shouldValidate: shouldMess,
            shouldTouch: shouldMess,
            shouldDirty: shouldMess,
        };
        // @ts-ignore
        setValue(`street`, addr.street, setValueOptions);
        // @ts-ignore
        setValue(`city`, addr.city, setValueOptions);
        // @ts-ignore
        setValue(`stateCode`, addr.stateCode, setValueOptions);
        // @ts-ignore
        setValue(`zip`, addr.zip, setValueOptions);

        setSearchAddress({});

        setVerifyAddress({
            street: addr.street,
            city: addr.city,
            stateCode: addr.stateCode,
            zip: addr.zip,
        });
    }

    const suggestedAddressRow = (addr: AddressInputVm, ix: number) => {
        return (
            <Button type='button' color='link' key={ix} className={styles.suggestedAddress}
                    onClick={() => setAddressFields(addr)}>
                {addr.street}, {addr.city}, {addr.stateCode}, {addr.zip}
            </Button>
        );
    }

    // if the fields are removed from the dom then 
    // rhf will not pick them up even if the value is set
    // use css to hide them to prevent that
    const addressFieldsClass = cn({
        'd-none': readOnly
    });
    
    return <>
        <Row>
            <Label className="bolded-text">{label}</Label>
        </Row>

        {before ??
            <Row>
                {before}
            </Row>}
        
        {consumer && !readOnly && <Row>
            <ConsumerAddressInput name={`${name}.id`} label="Saved Addresses" section={section}/>
        </Row>}

        {showAddressFields && <>
            <Row className={addressFieldsClass}>
                <Col>
                    <FormInputField name={`${name}.street`}
                                    label="Street"
                                    valid={isVerified}
                                    disabled={readOnly}
                                    requiredAsterisk
                                    autoComplete={getAutoComplete('address-line1')}
                    />
                    {suggestedAddresses.length > 0 &&
                        <div className={styles.suggestedAddresses}>
                            {suggestedAddresses.map(suggestedAddressRow)}
                        </div>
                    }
                </Col>
                <Col>
                    <FormInputField name={`${name}.city`}
                                    label="City"
                                    valid={isVerified}
                                    disabled={readOnly}
                                    autoComplete={getAutoComplete('address-level2')}
                                    requiredAsterisk
                    />
                </Col>
            </Row>
            <Row className={addressFieldsClass}>
                <Col>
                    <FormStateDropdown name={`${name}.stateCode`}
                                       label="State"
                                       valid={isVerified}
                                       disabled={readOnly}
                                       requiredAsterisk
                                       autoComplete={getAutoComplete('address-level1')}
                    />
                </Col>
                <Col>
                    <FormInputField name={`${name}.zip`}
                                    label="Zip"
                                    valid={isVerified}
                                    autoComplete={getAutoComplete('postal-code')}
                                    requiredAsterisk
                                    disabled={readOnly}
                    />
                </Col>
            </Row>
        </> }

        {readOnly &&
            <AddressLabel address={value} />}
    </>;
}

