import React, {useEffect, useRef, useState} from 'react';
import { Configuration } from '../configuration/Configuration';
import './AddressInput.css';
import { Button, FormFeedback, Input, Label, Spinner, Tooltip } from 'reactstrap';
import { ProductInputValue } from '../ProductInputValue';
import { useTranslation } from "react-i18next";
import { FieldModifier } from "../configuration/FieldModifier";
import { AddressInputVm } from "./Address";
import { useGetStatesQuery } from "../../../app/apiSlice";
import { RequiredAsterisk } from "../utils/RequiredAsterisk";
import { RuleTypes } from "../configuration/rule/ConfigurationRule";
import {MediaQueryTypes, useConfigurationRules, useMediaQuery, useSmartyStreets} from "../../../app/hooks";
import {
    formatPhoneNumber,
    formatZipCode,
    getConfigurationIsSecureClass,
    phoneNumberInputMaxLength
} from "../../../utils/Utils";
import { autoCompleteResolver } from "../../../utils/AutoCompleteResolver";
import { SavedAddressSelect } from "./SavedAddressSelect";
import { Address, DefaultAddressType } from "./Address";
import {OnValueChangeParams} from "../view/ProductInputView";
import {isUsingAdminSite} from "../../../utils/SiteHelper";

interface AddressInputProps {
    configuration?: Configuration,
    productInputValue?: ProductInputValue,
    isInEditMode: boolean,
    getClassesForModifiers: (fieldModifiers?: FieldModifier[]) => string,
    onValueChange: (params: OnValueChangeParams) => void,
    containsFieldModifier?: (valueKey: string, fieldModifiers?: FieldModifier[]) => boolean,
    checkInput: boolean,
    productVariantId?: number,
}

export const AddressInput = ({ configuration, productInputValue, isInEditMode, getClassesForModifiers, onValueChange, checkInput, productVariantId, containsFieldModifier }: AddressInputProps) => {
    const { t } = useTranslation();
    const inputId = configuration?.id ?? -1;
    const inputLabel = configuration?.configurationDetail.label ?? "";
    const isRequired = configuration?.configurationDetail.isRequired;
    const isAdminSite = isUsingAdminSite();
    const [ addressValue, setAddressValue ] = useState<AddressInputVm>(productInputValue?.value ? JSON.parse(productInputValue.value) : undefined);
    const { matches: suggestedAddresses, isVerified, isLoading } = useSmartyStreets(addressValue ?? {});
    const rulesState = useConfigurationRules(configuration?.rules, productInputValue?.value, productVariantId,[RuleTypes.Matching, RuleTypes.Differing, RuleTypes.PositiveMatch]);
    const [ tooltipEnabled, setTooltipEnabled ] = useState(false);
    const { data: states } = useGetStatesQuery();
    const showAddressFields = ((!addressValue?.id || addressValue?.id < 1) || isAdminSite);
    const [focusedInput, setFocusedInput] = useState<string>();
    const suggestionMenuRef = useRef<HTMLDivElement | null>(null);
    const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);

    useEffect(() => {
        setTimeout(function() {
            setTooltipEnabled(isInEditMode);
        });
    }, [isInEditMode]);

    useEffect(() => {
        onValueChange({ inputValue: JSON.stringify(addressValue), isComplete: hasAllRequiredProperties() });
    }, [addressValue, rulesState.inputShouldBeDisabled]);

    useEffect(() => {
        if (productInputValue?.value && productInputValue.value !== JSON.stringify(addressValue)) {
            const address = JSON.parse(productInputValue?.value ?? "");
            setAddressValue({ ...address, id: address.id ?? -1});
        }
    }, [productInputValue]);


    function onInputBlur(e: React.FocusEvent<HTMLInputElement>, name: string) {
        if (name === focusedInput && !suggestionMenuRef?.current?.contains(e.relatedTarget)) {
            // Delaying here because Safari on macOS desktop and all iOS browsers have slightly different timing
            // Seems that the onBlur event fires BEFORE the onclick event in these browsers, so there is an inprecise
            // Amount of delay that needs to be observed here. Realistically it could be as low as 101 ms from testing
            // But setting to 300 to be safe since there is no precise event we can await
            setTimeout(function() {
                setFocusedInput(undefined);
            }, 300);
        }
    }

    function shouldShowSuggestedAddresses() {
        return focusedInput && ((isMobile && focusedInput === 'streetAddress') || (!isMobile && focusedInput !== 'city' && focusedInput !== 'zip'));
    }

    function shouldInputBeDisabled(inputType: string) {
        const shouldInputBeDisabled = rulesState.inputShouldBeDisabled && inputType.includes(rulesState.disabledMetaTag ?? "")
        if (shouldInputBeDisabled && getInputValueByLabel(inputType)) {
            onAddressValueChange(inputType)
        }
        return shouldInputBeDisabled;
    }

    function hasAllRequiredProperties() {
        return !!((shouldInputBeDisabled('streetAddress') || addressValue?.street)
            && (shouldInputBeDisabled('city') || addressValue?.city)
            && (shouldInputBeDisabled('state') || addressValue?.stateCode)
            && (shouldInputBeDisabled('zip') || addressValue?.zip));
    }

    function onAddressValueChange(inputLabel: string, value?: string) {
        // Ensure whitespace not allowed at start of input values.
        value = value?.trimStart();
        
        switch (inputLabel) {
            case 'streetAddress':
                setAddressValue(old => ({...old, street: value, id: undefined, isVerified: false}));
                break;
            case 'city':
                setAddressValue(old => ({...old, city: value, id: undefined, isVerified: false}));
                break;
            case 'state':
                setAddressValue(old => ({...old, stateCode: value, id: undefined, isVerified: false}));
                break;
            case 'zip':
                const newZipValue = formatZipCode(value);
                setAddressValue(old => ({...old, zip: newZipValue, id: undefined, isVerified: false}));
                break;
            case 'phoneNumber':
                const newPhoneNumberValue = formatPhoneNumber(value);
                setAddressValue(old => ({...old, phoneNumber: newPhoneNumberValue}));
                break;
        }
    }

    function getInputLabel(labelType: string) {
        return (
            <Label for={`${labelType}-input-${inputId}`} className={`address-input-label ${getClassesForLabel()}`}>
                { t(`productConfiguration.${inputLabel}.${labelType}`) }
                {labelType !== 'phoneNumber' && isRequired && isInEditMode && !shouldInputBeDisabled(labelType) && <RequiredAsterisk></RequiredAsterisk> }
                { isLoading && labelType === 'streetAddress' && <Spinner size='sm' className='ms-1'/> }
            </Label>
        )
    }

    function getMaxLength(inputType: string) {
        switch (inputType) {
            case 'streetAddress':
                return 60;
            case 'city':
                return 40;
            case 'zip':
                return 10;
            case 'phoneNumber':
                return phoneNumberInputMaxLength;
            default:
                return undefined;
        }
    }

    function getInput(inputType: string) {
        return (
            <span className="text-input-edit-mode-container">
                <Input
                    className={getInputClasses()}
                    id={`${inputType}-input-${inputId}`}
                    type="text"
                    disabled={shouldInputBeDisabled(inputType)}
                    placeholder={ inputType === "phoneNumber" ? "(   ) ___ - ____" : ""}
                    maxLength={getMaxLength(inputType)}
                    onChange={(e) => onAddressValueChange(inputType, e.target.value)}
                    onFocus={() => setFocusedInput(inputType)}
                    onBlur={(event) => onInputBlur(event, inputType)}
                    value={getInputValueByLabel(inputType)}
                    autoComplete={autoCompleteResolver(getAutoCompleteType(inputType), (containsFieldModifier?.('disable-auto-complete') || isAdminSite || !!getInputValueByLabel(inputType)))}
                    invalid={ isInputInvalid(inputType) || (isSubmittedWithNull(inputType) && inputType !== 'phoneNumber') }
                    valid={inputType !== 'phoneNumber' && !!getInputValueByLabel(inputType) && isVerified}
                ></Input>
                {getErrorFeedback(inputType)}
            </span>
        )
    }

    function getInputClasses() {
        return getConfigurationIsSecureClass(configuration);
    }
    
    function getAutoCompleteType(inputType: 'streetAddress' | 'city' | 'zip' | 'phoneNumber' | 'state' | string): string | undefined {
        switch (inputType) {
            case 'streetAddress':
                return 'address-line1';
            case 'city':
                return 'address-level2';
            case 'state':
                return 'address-level1';
            case 'zip':
                return 'postal-code';
            case 'phoneNumber':
                return 'tel-national';
            default:
                return undefined;
        }
    }

    function getErrorFeedback(inputType: string) {
        let value = getInputValueByLabel(inputType);
        
        if (checkInput && value === '' && isRequired) {
            return <></>
        } else {
            return (
                <>
                    <FormFeedback tooltip>
                        {getInvalidInputText(inputType)}
                    </FormFeedback>
                    <Tooltip placement="bottom" isOpen={tooltipEnabled && shouldInputBeDisabled(inputType)} target={`${inputType}-input-${inputId}`}>
                        {rulesState.disabledRuleMessage}
                    </Tooltip>
                </>
            );
        }
    }

    function isInputInvalid(inputType: string) {
        return rulesState.brokenRules.findIndex(br => br.metaTag?.toLowerCase()?.includes(inputType.toLowerCase())) !== -1;
    }

    function isSubmittedWithNull(inputType: string) {
        let value = getInputValueByLabel(inputType);
        if (checkInput && value === '' && isRequired) {
            return true;
        }
    }

    function getInvalidInputText(inputType: string) {
        const brokenRuleIndex = rulesState.brokenRules.findIndex(br => br.metaTag?.toLowerCase()?.includes(inputType.toLowerCase()));
        return brokenRuleIndex !== -1 ? rulesState.brokenRules[brokenRuleIndex].ruleFailedMessage : '';
    }

    function getInputValueByLabel(label: string) {
        switch (label) {
            case 'zip':
                return addressValue?.zip ?? "";
            case 'streetAddress':
                return addressValue?.street ?? "";
            case 'city':
                return addressValue?.city ?? "";
            case 'state':
                return addressValue?.stateCode ?? "";
            case 'phoneNumber':
                return addressValue?.phoneNumber ?? "";
        }
    }

    function doesAddressHaveAnyValue() {
        return addressValue && (addressValue.street || addressValue.city || addressValue.zip || addressValue.stateCode);
    }

    function onSuggestedAddressClick (matchingAddress?: Address) {
        setAddressValue({...matchingAddress, phoneNumber: addressValue?.phoneNumber, id: matchingAddress?.id ?? -1, isVerified: true});
    }
    
    function getEditModeTemplate() {
        return (
            <>
                {/*Saved Addresses*/}
                <SavedAddressSelect
                    className='full-width-text-input'
                    defaultAddress={DefaultAddressType.Shipping}
                    shouldUseDefault={!doesAddressHaveAnyValue()}
                    selectedAddressId={addressValue?.id}
                    onAddressSelected={addr => onSuggestedAddressClick(addr)}/>

                { showAddressFields &&
                    <>
                        {/*Street Address*/}
                        <span className="full-width-text-input position-relative">
                            {getInputLabel('streetAddress')}
                            {getInput('streetAddress')}
                            { !isVerified && suggestedAddresses.length > 0 && shouldShowSuggestedAddresses() &&
                                <div className='address-input-configuration-suggested-addresses'
                                     ref={suggestionMenuRef}>
                                    { suggestedAddresses.map((suggestedAddress, ix) => (
                                        <Button type='button' color='link' key={ix} className='address-input-configuration-suggested-address-btn' onClick={() => onSuggestedAddressClick(suggestedAddress)}>
                                            {suggestedAddress.street}, {suggestedAddress.city}, {suggestedAddress.stateCode}, {suggestedAddress.zip}
                                        </Button>
                                    ))}
                                </div>
                            }
                        </span>

                        {/*City*/}
                        <span className="half-width-text-input">
                            {getInputLabel('city')}
                            {getInput('city')}
                        </span>

                        {/*State*/}
                        <span className="half-width-text-input">
                            {getInputLabel('state')}
                            <Input
                                id={`state-input-${inputId}`}
                                type="select"
                                autoComplete='address-level1'
                                onChange={(e) => onAddressValueChange('state', e.target.value)}
                                onFocus={() => setFocusedInput('state')}
                                onBlur={(event) => onInputBlur(event, 'state')}
                                value={getInputValueByLabel('state')}
                                invalid={isSubmittedWithNull('state')}
                                valid={!!getInputValueByLabel('state') && isVerified}>
                            <option></option>
                            {states?.map((state) => {
                                return (
                                    <option key={state.stateCode} value={state.stateCode}>{state.name}</option>
                                )
                            })}
                            </Input>
                        </span>

                        {/*Zip*/}
                        <span className="half-width-text-input">
                            {getInputLabel('zip')}
                            {getInput('zip')}
                        </span>
                    </>
                }
                

                {/*Phone Number*/}
                <span className={showAddressFields ? 'half-width-text-input' : 'full-width-text-input'}>
                    {getInputLabel('phoneNumber')}
                    {getInput('phoneNumber')}
                </span>

                { !isVerified && hasAllRequiredProperties() && (addressValue?.id ?? -1) === -1 && !isLoading &&
                    <div className='alert alert-warning w-100'>
                        {t('productConfiguration.standardAddressLabel.couldNotVerify')}
                        { suggestedAddresses.length > 0 && (' ' + t('productConfiguration.standardAddressLabel.chooseSuggestedAddress'))}
                    </div>
                }
            </>

        );
    }

    function getAddressValue() {
        return (
            <>
                <div className={`half-width-text-input ${getConfigurationIsSecureClass(configuration)}`}>
                    <div>{addressValue?.street ?? ""}</div>
                    <div>{`${addressValue?.city ?? ""}${addressValue?.city ? "," : ""} ${addressValue?.stateCode ?? ""} ${addressValue?.zip ?? ""}`}</div>
                    <div>{addressValue?.phoneNumber ?? ""}</div>
                </div>
            </>
        )
    }

    function getReadModeTemplate() {
        return (
            <>
                {getInputLabel('overallAddress')}
                {getAddressValue()}
            </>
        );
    }

    function getValueRenderTemplate() {
        if (isInEditMode) {
            return getEditModeTemplate();
        }
        else {
            return getReadModeTemplate();
        }
    }

    function getClassesForContainer() {
        if (isInEditMode) {
            return "address-input-container-edit-mode";
        }
        return "";
    }

    function getClassesForLabel() {
        if (!isInEditMode) {
            return "half-width-text-input";
        }
        return "";
    }

    return (
        <div className={"address-input-container " + getClassesForModifiers(configuration?.fieldModifiers) + " " + getClassesForContainer()}>
            {getValueRenderTemplate()}
        </div>
    );
}
