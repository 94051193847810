import {useTranslation} from "react-i18next";
import {OrderTracking} from "../../../../Order";
import './TrackingDetailsItem.css';
import {isUsingAdminSite} from "../../../../../../utils/SiteHelper";

interface TrackingDetailsItemProps {
	orderTracking: OrderTracking;
}

export const TrackingDetailsItem = (props: TrackingDetailsItemProps) => {
	const {t} = useTranslation();

	const getTrackingLink = () => {
		// Display link for FedEx.
		if (props.orderTracking.trackingUrl && props.orderTracking.trackingNumber) {
			return (
				<td>
					<a className="tracking-link" target="_blank"
					   href={`${props.orderTracking.trackingUrl}${props.orderTracking.trackingNumber}`}>
						{props.orderTracking.trackingNumber}
					</a>
				</td>
			)
		}

		// Link to USPS scan report in SSRS (admin only).
		if (isUsingAdminSite() && props.orderTracking.trackingNumber && props.orderTracking.carrier === "SiteProvided") {
			const reportBaseUrl = "http://msi-sqlbham1/ReportServer/Pages/ReportViewer.aspx?%2fPostalScanData&ImbSerialNumber=";
			const imb = props.orderTracking.trackingNumber.substring(11, 20);
			return (
				<td>
					<a className="tracking-link" target="_blank" href={`${reportBaseUrl}${imb}`}>
						{props.orderTracking.trackingNumber}
					</a>
				</td>
			)
		}

		// Branwin DuBose: not sure how to display link, but can at least show tracking number if available
		if (props.orderTracking.trackingNumber) {
			return <td>{props.orderTracking.carrier} - {props.orderTracking.trackingNumber}</td>
		}

		if (!props.orderTracking.isShipped) {
			return <td>Not yet shipped</td>
		}

		return <td>{t(`tracking.trackingTable.unavailable`)}</td>
	}

	const getShippedText = () => {
		return props.orderTracking.isShipped ? "Shipped" : props.orderTracking.order.status;
	}

	const formatDate = (date: string) => {
		const convertToDate = new Date(date + 'Z');

		return convertToDate.toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		});
	}

	return (
		<tr>
			<td>{props.orderTracking.productName}</td>
			<td>{getShippedText()}</td>
			<td>{formatDate(props.orderTracking.order.timeCreated)}</td>
			<td>{props.orderTracking.quantity}</td>
			<td>{props.orderTracking.shippingOptionName}</td>
			{getTrackingLink()}
		</tr>
	);
}
