import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {CreditCardEntryFields, CreditCardPaymentDetails} from "../../CreditCardPaymentDetails";
import React, { ChangeEvent, Ref, useCallback, useEffect, useRef, useState } from "react";
import {PaymentDetails} from "../../PaymentDetails";
import {Address} from "../../../input/address/Address";
import "./CreditCardDetailsEntry.css";
import {RequiredAsterisk} from "../../../input/utils/RequiredAsterisk";
import {ValidationHook} from "../../../../app/hooks";
import {SavedPaymentDetails} from "../../SavedPaymentDetails";
import {isUsingAdminSite} from "../../../../utils/SiteHelper";
import {FULL_STORY_SECURE_ELEMENT_CLASS} from "../../../input/ProductInput";

interface CreditCardDetailsEntryProps {
    billingAddress?: Address,
    paymentMethodId: number,
    onPaymentDetailsChanged: (paymentDetails?: PaymentDetails) => void,
    paymentInfo?: PaymentDetails,
    validation?: ValidationHook
    savedPaymentDetails?: SavedPaymentDetails,
}

export const CreditCardDetailsEntry = ({billingAddress, paymentMethodId, savedPaymentDetails, onPaymentDetailsChanged, validation}: CreditCardDetailsEntryProps) => {
    const [ cardNumber, setCardNumber ] = useState<string | undefined>(undefined);
    const [ cardNumberDisplay, setCardNumberDisplay ] = useState<string | undefined>(undefined);
    const [ expirationDateString, setExpirationDateString ] = useState<string | undefined>(undefined);
    const [ expirationYearString, setExpirationYearString ] = useState<string | undefined>(undefined);
    const [ expirationMonthString, setExpirationMonthString ] = useState<string | undefined>(undefined);
    const [ cvc, setCvc ] = useState<string | undefined>(undefined);
    const [ firstName, setFirstName ] = useState<string | undefined>(savedPaymentDetails?.firstName);
    const [ lastName, setLastName ] = useState<string | undefined>(savedPaymentDetails?.lastName);
    const [ paymentDetails, setPaymentDetails ] = useState<CreditCardPaymentDetails | undefined>(undefined);
    const yearInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        onPaymentDetailsChanged(paymentDetails);
    }, [paymentDetails]);

    const checkForAllRequiredValues = useCallback(() => {
        if (cardNumber && expirationDateString && cvc && billingAddress && billingAddress?.street && firstName && lastName) {
            setPaymentDetails({paymentMethodId, cardNumber, expirationDateString, cvc, billingAddress, firstName, lastName, paymentDetailsType: "CreditCardPaymentDetails"})
        } else if (paymentDetails) {
            setPaymentDetails(undefined);
        }

        validation?.setValue('fullName', `${firstName ?? ""} ${lastName ?? ""}`);
    }, [setPaymentDetails, cardNumber, expirationDateString, cvc, billingAddress, firstName, lastName]);

    useEffect(() => {
        checkForAllRequiredValues();
    }, [billingAddress, checkForAllRequiredValues]);

    useEffect(() => {
        firstName && validation?.setValue('firstName', firstName)
        lastName && validation?.setValue('lastName', lastName)
    }, [firstName, lastName])
    
    useEffect(() => {
        if (expirationMonthString && expirationYearString) {
            setExpirationDateString(expirationMonthString + expirationYearString);
        }
        else {
            setExpirationDateString(undefined);
        }
        if (expirationMonthString && expirationMonthString.length >= 2 && !expirationYearString) {
            yearInputRef.current?.focus();
        }

    }, [expirationMonthString, expirationYearString]);

    useEffect(() => {
        validation?.setValue('cardExpiration', expirationDateString);
    }, [expirationDateString]);

    const validateAndHandle = (validationKey: string, e: ChangeEvent<HTMLInputElement>) => {
        validation?.setValue(validationKey, e.target.value);
    }

    const onPaymentDetailsEntered = (entryField: CreditCardEntryFields, entryValue: string, e: ChangeEvent<HTMLInputElement>) => {
        switch (entryField) {
            case (CreditCardEntryFields.CardNumber):
                validateAndHandle('cardNumber', e);
                onCardNumberChanged(entryValue);
                break;
            case (CreditCardEntryFields.ExpirationMonth):
                entryValue = entryValue.replace(/\D/, '');
                setExpirationMonthString(formatExpirationMonth(entryValue));
                break;
            case (CreditCardEntryFields.ExpirationYear):
                entryValue = entryValue.replace(/\D/, '');
                setExpirationYearString(entryValue);
                break;
            case (CreditCardEntryFields.CVC):
                entryValue = entryValue.replace(/\D/, '');
                validateAndHandle('cvc', e);
                setCvc(entryValue);
                break;
            case (CreditCardEntryFields.FirstName):
                validateAndHandle('firstName', e);
                setFirstName(entryValue);
                break;
            case (CreditCardEntryFields.LastName):
                validateAndHandle('lastName', e);
                setLastName(entryValue);
                break;
        }

        checkForAllRequiredValues();

    }

    const onCardExpirationMonthBlur = () => {
        setExpirationMonthString(formatExpirationMonth(expirationMonthString, true));
    }

    const formatExpirationMonth = (expirationMonthValue?: string, includeLeadingZero: boolean = false) => {
        if (!expirationMonthValue)
            return expirationMonthValue;
        const expirationMonthInt = Number.parseInt(expirationMonthValue);
        if (expirationMonthInt < 0)
            return "1";
        if (expirationMonthInt < 10 && includeLeadingZero)
            return `0${expirationMonthInt}`;
        if (expirationMonthInt > 12)
            return "12";
        return expirationMonthValue;
    }

    const onCardNumberChanged = (cardNumber?: string) => {
        cardNumber = cardNumber ? cardNumber.replace(/[^0-9 ]/ig, "") : cardNumber;
        if(cardNumber?.includes(' ')) {
            cardNumber = cardNumber.replaceAll(' ', '');
        }
        setCardNumber(cardNumber);

        const pattern = cardNumber?.startsWith('34') || cardNumber?.startsWith('37') ?
            '#### ###### #####' : // AMEX
            '#### #### #### ####'; // others
        
        let i = 0;
        setCardNumberDisplay(pattern.replace(/#/g, _ => (cardNumber ?? '')[i++] ?? '').trim());
    }
    
    const isAdminAutoFill = (autofill: string) => isUsingAdminSite() ? 'autocomplete_off' : autofill;

    return (
        <span className="credit-card-details-entry-container">
            <span className="credit-card-details-entry-container-row">
                <FormGroup className="payment-details-entry-input-container">
                    <Label for="credit-card-first-name">First Name
                        <RequiredAsterisk />
                    </Label>
                    <Input id="credit-card-first-name"
                           required={true}
                           type="text"
                           value={firstName ?? ""}
                           maxLength={50}
                           invalid={!!validation?.getError('firstName') || !!validation?.getError('fullName')}
                           onChange={(e) => onPaymentDetailsEntered(CreditCardEntryFields.FirstName, e.target.value, e)}
                           onBlur={e => onPaymentDetailsEntered(CreditCardEntryFields.FirstName, e.target.value, e)}
                           autoComplete={isAdminAutoFill("cc-given-name")}
                           className={`payment-details-entry-first-name-input ${FULL_STORY_SECURE_ELEMENT_CLASS}`}></Input>
                    <FormFeedback>{validation?.getError('firstName') ?? validation?.getError('fullName')}</FormFeedback>
                </FormGroup>
                <FormGroup className="payment-details-entry-input-container">
                    <Label for="credit-card-last-name">
                        Last Name
                        <RequiredAsterisk />
                    </Label>
                    <Input id="credit-card-last-name"
                           required={true}
                           type="text"
                           value={lastName ?? ""}
                           maxLength={50}
                           invalid={!!validation?.getError('lastName')}
                           onChange={(e) => onPaymentDetailsEntered(CreditCardEntryFields.LastName, e.target.value, e)}
                           onBlur={e => onPaymentDetailsEntered(CreditCardEntryFields.LastName, e.target.value, e)}
                           autoComplete={isAdminAutoFill("cc-family-name")}
                           className={`payment-details-entry-last-name-input ${FULL_STORY_SECURE_ELEMENT_CLASS}`}></Input>
                    <FormFeedback>{validation?.getError('lastName')}</FormFeedback>
                </FormGroup>
            </span>
            <span className="credit-card-details-entry-container-row">
                <FormGroup className="payment-details-entry-input-container">
                    <Label for="credit-card-number">
                        Credit Card Number
                        <RequiredAsterisk />
                    </Label>
                    <Input id="credit-card-number"
                           required={true}
                           type="tel"
                           invalid={!!validation?.getError('cardNumber')}
                           onChange={(e) => onPaymentDetailsEntered(CreditCardEntryFields.CardNumber, e.target.value, e)}
                           onBlur={e => onPaymentDetailsEntered(CreditCardEntryFields.CardNumber, e.target.value, e)}
                           inputMode="numeric"
                           value={cardNumberDisplay ?? ''}
                           pattern="[0-9\s]{13,19}"
                           autoComplete={isAdminAutoFill("cc-number")}
                           maxLength={19}
                           placeholder="xxxx xxxx xxxx xxxx"
                           className={`payment-details-entry-card-input ${FULL_STORY_SECURE_ELEMENT_CLASS}`}></Input>
                    <FormFeedback>{validation?.getError('cardNumber')}</FormFeedback>
                </FormGroup>
            </span>
            <span className="credit-card-details-entry-container-row">
                <FormGroup className="payment-details-entry-input-container">
                    <Label for="credit-card-expiration-month">
                        Expiration
                        <RequiredAsterisk />
                    </Label>

                    <div className="payment-details-entry-card-expiration-container">
                        <Input id="credit-card-expiration-month"
                               required={true}
                               type="text"
                               maxLength={2}
                               placeholder="MM"
                               inputMode='numeric'
                               pattern="[0-9]{2}"
                               invalid={!!validation?.getError('cardExpiration')}
                               onBlur={onCardExpirationMonthBlur}
                               onChange={(e) => onPaymentDetailsEntered(CreditCardEntryFields.ExpirationMonth, e.target.value, e)}
                               autoComplete={isAdminAutoFill("cc-exp-month")}
                               value={expirationMonthString ?? ""}
                               className={`payment-details-entry-card-expiration-input ${FULL_STORY_SECURE_ELEMENT_CLASS}`}></Input>

                        <Input id="credit-card-expiration-year"
                               required={true}
                               type="text"
                               maxLength={2}
                               placeholder="YY"
                               inputMode='numeric'
                               pattern="[0-9]{2}"
                               innerRef={yearInputRef as Ref<HTMLInputElement>}
                               invalid={!!validation?.getError('cardExpiration')}
                               onChange={(e) => onPaymentDetailsEntered(CreditCardEntryFields.ExpirationYear, e.target.value, e)}
                               onBlur={e => onPaymentDetailsEntered(CreditCardEntryFields.ExpirationYear, e.target.value, e)}
                               autoComplete={isAdminAutoFill("cc-exp-year")}
                               value={expirationYearString ?? ""}
                               className={`payment-details-entry-card-expiration-input ${FULL_STORY_SECURE_ELEMENT_CLASS}`}></Input>
                    </div>
                    {/*Invisible input here for payment expiration form feedback anchor*/}
                    <Input className="payment-details-entry-invisible-input" invalid={!!validation?.getError('cardExpiration')}></Input>
                    <FormFeedback>{validation?.getError('cardExpiration')}</FormFeedback>

                </FormGroup>
                <FormGroup className="payment-details-entry-input-container">
                    <Label for="credit-card-cvc">
                        CVC
                        <RequiredAsterisk />
                    </Label>
                    <Input id="credit-card-cvc"
                           required={true}
                           type="text"
                           inputMode='numeric'
                           pattern='\d{3,4}'
                           invalid={!!validation?.getError('cvc')}
                           onChange={(e) => onPaymentDetailsEntered(CreditCardEntryFields.CVC, e.target.value, e)}
                           onBlur={e => onPaymentDetailsEntered(CreditCardEntryFields.CVC, e.target.value, e)}
                           autoComplete={isAdminAutoFill("cc-csc")}
                           maxLength={4}
                           value={cvc ?? ''}
                           className={`payment-details-entry-cvc-input ${FULL_STORY_SECURE_ELEMENT_CLASS}`}></Input>
                    <FormFeedback>{validation?.getError('cvc')}</FormFeedback>
                </FormGroup>
            </span>
        </span>
    );

}