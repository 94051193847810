import {Controller, FieldValues, useFormContext} from "react-hook-form";
import {FormInputProps} from "./FormInputField";
import {FormFeedback, FormGroup, Input, InputGroup, Label} from "reactstrap";
import {RequiredAsterisk} from "../input/utils/RequiredAsterisk";
import React, {useEffect, useId} from "react";

interface FormRadioGroupProps<TSchema extends FieldValues>
    extends Omit<FormInputProps<TSchema>, 'type' | 'placeholder'> {

    readonly options?: ReadonlyArray<{ text: string, value: string | number }>;
}

export function FormRadioGroup<TSchema extends FieldValues>(
    {
        control,
        name,
        label,
        options = []
    }: FormRadioGroupProps<TSchema>) {

    const {watch, trigger} = useFormContext();
    const value = watch(name);

    useEffect(() => {
        value && trigger(name);
    }, [value]);

    return (
        <Controller name={name} control={control} render={({field, fieldState}) => {
            return (
                <>
                    {
                        options.map(o => {
                            const id = `${name.replaceAll('.', '-')}-${o.value}`;
                            return (
                                <FormGroup key={o.value}>
                                    <Input
                                        type="radio"
                                        id={id}
                                        {...field}
                                        value={o.value}
                                        invalid={fieldState.invalid}
                                        check
                                    />
                                    <Label htmlFor={id} check className={'ms-2'}>{o.text}</Label>
                                </FormGroup>
                            );
                        })
                    }
                </>
            );
        }}
        />
    );
}

