import SmartySDK from 'smartystreets-javascript-sdk';
import {AddressInputVm} from "../components/input/address/Address";

const SmartyCore = SmartySDK.core;
const SmartyCoreValidation = SmartySDK.core;
const Lookup = SmartySDK.usStreet.Lookup;
const AutocompleteLookup = SmartySDK.usAutocompletePro.Lookup;

const websiteVerificationKey = process.env.REACT_APP_SMARTY_ADDRESS_VERIFICATION_API_KEY ?? process.env.REACT_APP_SMARTY_API_KEY ?? "";
const websiteAutocompleteKey = process.env.REACT_APP_SMARTY_ADDRESS_AUTOCOMPLETE_API_KEY ?? process.env.REACT_APP_SMARTY_API_KEY ?? "";
const verificationCredentials = new SmartyCore.SharedCredentials(websiteVerificationKey);
const autocompleteCredentials = new SmartyCore.SharedCredentials(websiteAutocompleteKey);

let verificationClientBuilder = new SmartyCoreValidation.ClientBuilder(verificationCredentials);
let verificationClient = verificationClientBuilder.buildUsStreetApiClient();

let autocompleteClientBuilder = new SmartyCore.ClientBuilder(autocompleteCredentials).withLicenses(["us-autocomplete-pro-cloud"]);
let autocompleteClient = autocompleteClientBuilder.buildUsAutocompleteProClient();
// Documentation link
// https://www.smarty.com/docs/sdk/javascript#under-the-hood


/**
 * Use the useSmartyStreets hook instead of calling this function directly
 */
export const validateAddress = async (address: AddressInputVm): Promise<[AddressInputVm | undefined, boolean]> => {
    if (!address?.street || !address?.city || !address?.zip || !address?.stateCode)
        return [undefined, false];
    let lookup = new Lookup();
    lookup.street = address.street ?? "";
    lookup.street2 = address.street2 ?? "";
    lookup.city = address.city ?? "";
    lookup.state = address.stateCode ?? "";
    lookup.zipCode = address.zip ?? "";
    lookup.maxCandidates = 3;
    lookup.match = "strict"; // "strict" The API will return detailed output only if a valid match is found. Otherwise the API response will be an empty array.

    const matches = await handleValidateAddressResponse(lookup);
    const firstMatch = matches && matches.find(lookup => true)?.result.find(r => true);
    const enteredMatchesSuggested =firstMatch &&
        firstMatch.deliveryLine1.trim().toUpperCase() === address.street.trim().toUpperCase() &&
        firstMatch.components.cityName.trim().toUpperCase() === address.city.trim().toUpperCase() &&
        firstMatch.components.state.trim().toUpperCase() === address.stateCode.trim().toUpperCase() &&
        (firstMatch.components.zipCode.trim().toUpperCase() === address.zip.trim().toUpperCase() || `${firstMatch.components.zipCode.trim().toUpperCase()}-${firstMatch.components.plus4Code.trim().toUpperCase()}` === address.zip.trim().toUpperCase());

    return [firstMatch && {
        street: firstMatch.deliveryLine1,
        city: firstMatch.components.cityName,
        zip: `${firstMatch.components.zipCode}-${firstMatch.components.plus4Code}`,
        stateCode: firstMatch.components.state
    }, !!enteredMatchesSuggested];
};

//: Promise<SmartySDK.usStreet.Lookup>
async function handleValidateAddressResponse(lookup: SmartySDK.usStreet.Lookup) {
    try {
        const result = await verificationClient.send(lookup);
        return result.lookups;
    } catch(err) {
        console.error(err);
    }
}


/**
 * Use the useSmartyStreets hook instead of calling this function directly
 */
export const autocompleteAddress = async (address?: AddressInputVm) => {
    if (!address?.street)
        return [] as AddressInputVm[];
    let lookup = new AutocompleteLookup(address.street);
    // Can only include city if state is also included
    if (address.city && address.stateCode)
        lookup.includeOnlyCities = [`${address.city},${address.stateCode}`];
    else if (address.stateCode)
        lookup.includeOnlyStates = [address.stateCode];

    // Can only use zip if city and state aren't being used
    if (!address.city && !address.stateCode && address.zip)
        lookup.includeOnlyZIPCodes = [address.zip];

    lookup.maxResults = 10;
    lookup.preferRatio = 33;

    return await handleAutocompleteResponse(lookup);
};


async function handleAutocompleteResponse(lookup: SmartySDK.usAutocompletePro.Lookup): Promise<AddressInputVm[]> {

    try {
        const response = await autocompleteClient.send(lookup);
        return response.result.map(s => {
            return {
                street: s.secondary ? `${s.streetLine} ${s.secondary}` : s.streetLine, // ` ${s.secondary}` is concatenated to end of deliveryLine1 by Smarty
                city: s.city,
                stateCode: s.state,
                zip: s.zipcode
            };
        });
    } catch(err) {
        console.log(err)
    }
    return [];
}

