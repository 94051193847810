import {Control, Controller, ControllerRenderProps, FieldPath, FieldValues} from "react-hook-form";
import React, {ChangeEvent, PropsWithChildren} from "react";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import classnames from 'classnames';

import {RequiredAsterisk} from "../input/utils/RequiredAsterisk";
import {curry} from "lodash";

type InputType = 'text' | 'email' | 'tel' | 'radio' | 'select' | 'checkbox';

type FormInputOnChangeHandler = (value: any) => any;

export interface FormInputProps<Type extends FieldValues> {
    readonly control?: Control<Type>;
    readonly name: FieldPath<Type>;
    readonly label: string;
    readonly disabled?: boolean;
    readonly type?: InputType;
    readonly placeholder?: string;
    readonly onChange?: FormInputOnChangeHandler,
    readonly valid?: boolean;
    readonly requiredAsterisk?: boolean;
    readonly className?: string;
    readonly labelClassName?: string;
    readonly autoComplete?: string;
    readonly readOnly?: boolean;
    readonly plaintext?: boolean;
    readonly defaultChecked?: boolean;
}

export function FormInputField<Type extends FieldValues>(
    {
        control,
        label,
        name,
        placeholder,
        valid,
        className,
        labelClassName,
        children,
        autoComplete,
        defaultChecked = false,
        disabled = false,
        type = 'text',
        readOnly = false,
        plaintext = false,
        requiredAsterisk = false,
        onChange = (value) => value
    }: FormInputProps<Type> & PropsWithChildren) {

    const labelFirst = type !== 'radio' && type !== 'checkbox';
    const labelClassnames = classnames(labelClassName, {
        'ms-2': !labelFirst
    });

    function renderLabel() {
        return <Label for={name} className={labelClassnames}>
            {label}
            {requiredAsterisk && <RequiredAsterisk/>}
        </Label>;
    }

    return (
        <FormGroup>
            {labelFirst && renderLabel()}
            <Controller
                name={name}
                control={control}
                render={
                    ({field, fieldState}) =>
                        (
                            <>
                                <Input id={name}
                                       type={type}
                                       invalid={fieldState.invalid}
                                       valid={valid}
                                       placeholder={placeholder}
                                       className={className}
                                       autoComplete={autoComplete}
                                       disabled={disabled}
                                       {...field}
                                       readOnly={readOnly}
                                       plaintext={plaintext}
                                       defaultChecked={defaultChecked}
                                       onChange={handleOnChange(type, onChange, field)}>
                                    {children}
                                </Input>
                                {fieldState.invalid
                                    && <FormFeedback>{fieldState.error?.message}</FormFeedback>}
                            </>
                        )
                }/>
            {!labelFirst && renderLabel()}
        </FormGroup>
    );
}


const handleOnChange = curry(function <Type extends FieldValues>(
    type: InputType,
    onChange: FormInputOnChangeHandler,
    field: ControllerRenderProps<Type>,
    event: ChangeEvent<HTMLInputElement>) {
    
    switch (type) {
        case 'checkbox':
            field.onChange(onChange(event.target.checked))
            break;
        default:
            field.onChange(onChange(event.target.value))
    }
});
    