import { ControlListGroupNames } from "./edit/ControlListGroupNames";

export interface ControlListGroup {
    id: number,
    name: ControlListGroupNames,
    valueOneType: ControlListValueType,
    valueTwoType: ControlListValueType,
    stringValueOneLabel?: ControlListValueLabel,
    stringValueTwoLabel?: ControlListValueLabel
}

export enum ControlListValueType {
    None = 'None',
    String = 'String',
    Address = 'Address',
    UserId = 'UserId'
}

export enum ControlListValueLabel {
    AccountNumber = 'Account Number',
    Name = 'Name',
    Email = 'Email',
    Address = 'Address',
    Consumer = 'Consumer',
    Aba = 'ABA',
    ValueOne = 'Value One',
    ValueTwo = 'Value Two',
    PhoneNumber = 'Phone Number',
}