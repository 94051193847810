import {Configuration} from '../configuration/Configuration';
import {Component} from '../component/Component';
import './InputText.css';
import {FormFeedback, Input, Label, Spinner} from 'reactstrap';
import {ProductInputValue} from '../ProductInputValue';
import {FieldModifier} from "../configuration/FieldModifier";
import {RequiredAsterisk} from "../utils/RequiredAsterisk";
import {ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useEffect, useState} from "react";
import {debounce} from "lodash";
import {useAppSelector, useConfigurationRules} from "../../../app/hooks";
import {RuleTypes} from "../configuration/rule/ConfigurationRule";
import { CurrencyFormatter } from '../../../utils/CurrencyFormatter';
import {getConfigurationIsSecureClass} from "../../../utils/Utils";
import {autoCompleteResolver} from "../../../utils/AutoCompleteResolver";
import {selectAllInputValues} from "../ProductInputSlice";
import {isUsingAdminSite} from "../../../utils/SiteHelper";
import {selectCurrentConsumerUser} from "../../user/login/AuthenticationSlice";
import {OnValueChangeParams} from "../view/ProductInputView";

interface InputTextProps {
    configuration?: Configuration,
    component?: Component,
    productInputValue?: ProductInputValue,
    isInEditMode: boolean,
    getClassesForModifiers: (fieldModifiers?: FieldModifier[]) => string,
    containsFieldModifier    : (valueKey: string, fieldModifiers?: FieldModifier[]) => boolean,
    onValueChange: (params: OnValueChangeParams) => void,
    validateValue: (value?: ProductInputValue) => void,
    checkInput: boolean,
    productVariantId?: number,
    toBeNumeric?: boolean,
}

export const InputText = ({ configuration, productInputValue, isInEditMode, getClassesForModifiers, containsFieldModifier,
                              onValueChange, validateValue, checkInput, productVariantId, toBeNumeric = false }: InputTextProps) => {
    const inputId = configuration?.id ?? -1;
    const inputLabel = (isInEditMode && configuration?.configurationDetail.actionLabel ? configuration?.configurationDetail.actionLabel : configuration?.configurationDetail.label) ?? "";
    const isRequired = configuration?.configurationDetail.isRequired;
    const waitToVerifyUntilBlur = containsFieldModifier('wait-to-validate-until-onblur', configuration?.fieldModifiers);
    const [bypassConfigurationRules, setBypassConfigurationRules] = useState(waitToVerifyUntilBlur);
    const rulesState = useConfigurationRules(configuration?.rules, productInputValue?.value, productVariantId, [RuleTypes.Matching, RuleTypes.Differing, RuleTypes.PositiveMatch], bypassConfigurationRules);
    const productInputs = useAppSelector(selectAllInputValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceValidation = useCallback(debounce(validateIfRulesPass, 2000), [productInputs]);
    const consumerDetails = useAppSelector(selectCurrentConsumerUser);
    const isAdminSite = isUsingAdminSite();

    useEffect(() => {
        if (!productInputValue?.isDirty && !productInputValue) {
            const defaultValue = autoFillNameFromProfile();
            if (defaultValue)
                onValueChange({ inputValue: defaultValue });
        }
        // On initial load validate value
        if(configuration?.shouldValidateValue && productInputValue && productInputValue?.isValidated === undefined) {
            validateIfRulesPass(rulesState.inputIsInvalid, productInputValue);
        }
    }, []);

    function validateIfRulesPass(inputIsInvalid: boolean, value?: ProductInputValue) {
        if (!inputIsInvalid) validateValue(value);
    }

    function onInputChange(value: string) {
        // Ensure whitespace not allowed at start of input values.
        value = value?.trimStart();
        onValueChange({ inputValue: value, bypassValidation: waitToVerifyUntilBlur });
    }

    function getHelperText() {
        if (configuration?.helperText) {
            return (
                <span>{configuration.helperText}</span>
            );
        } else {
            return <></>
        }
    }

    function shouldInputBeDisabled() {
        return containsFieldModifier?.('disable-entry', configuration?.fieldModifiers);
    }

    function autoFillNameFromProfile() {
        if (configuration?.autoCompleteValue !== 'name' || !consumerDetails || isAdminSite) return '';
        const firstName = consumerDetails?.firstName ?? '';
        const lastName = consumerDetails?.lastName ?? '';
        return `${firstName} ${lastName}`;
    }

    function getEditModeTemplate() {
        const inputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (waitToVerifyUntilBlur) {
                if (e.target.value && rulesState.inputIsInvalid) {
                    setBypassConfigurationRules(false);
                } else {
                    setBypassConfigurationRules(true);
                }
            }
            onInputChange(e.target.value)
        };
        const inputOnKeyUp = (_: KeyboardEvent<HTMLInputElement>) => {
            if (!waitToVerifyUntilBlur) {
                debounceValidation(rulesState.inputIsInvalid, productInputValue)
            }
        };
        const inputOnBlur = (_: FocusEvent<HTMLInputElement>) => {
            if (waitToVerifyUntilBlur) {
                setBypassConfigurationRules(false);
                onValueChange({ inputValue: productInputValue ? productInputValue.value : "", bypassValidation: waitToVerifyUntilBlur });
                validateIfRulesPass(rulesState.inputIsInvalid, productInputValue);
            } else {
                debounceValidation(rulesState.inputIsInvalid, productInputValue)
            }
        };

        return (
            <span>
                <span className="text-input-edit-mode-container">
                    <Input
                        className={getInputClasses()}
                        id={`text-input-${inputId}`}
                        type='text'
                        pattern={toBeNumeric ? "\d*" : ""}
                        inputMode={toBeNumeric ? 'numeric' : 'text'}
                        value={productInputValue?.value || ""}
                        onChange={inputOnChange}
                        onKeyUp={inputOnKeyUp}
                        onBlur={inputOnBlur}
                        required={isRequired}
                        invalid={rulesState.inputIsInvalid
                            || (configuration?.shouldValidateValue && productInputValue?.isValidated === false)
                            || (configuration?.shouldValidateValue && productInputValue?.showWarning === true)}
                        disabled={shouldInputBeDisabled()}
                        autoComplete={autoCompleteResolver(configuration, (containsFieldModifier('disable-auto-complete', configuration?.fieldModifiers) || isAdminSite || !!productInputValue?.value))}
                    ></Input>
                    <FormFeedback className={getTooltipClasses()} tooltip>
                        {getInvalidMessage()}
                    </FormFeedback>
                </span>
                {getHelperText()}
            </span>
        );
    }

    function getTooltipClasses() {
        return !rulesState.inputIsInvalid && productInputValue?.isValidated !== false && productInputValue?.showWarning === true
            ? 'text-input-warning-tooltip' : '';
    }

    function getInputClasses() {
        let classNames = getConfigurationIsSecureClass(configuration);
        let displayRequired = checkInput && isRequired && productInputValue?.value === '';

        if (displayRequired || rulesState.inputIsInvalid || (configuration?.shouldValidateValue && productInputValue?.isValidated === false)) {
            classNames += " invalid-text-input";
        }
        else if (displayRequired || rulesState.inputIsInvalid || (configuration?.shouldValidateValue && productInputValue?.showWarning === true)) {
            classNames += " warning-text-input";
        }
        else if (configuration?.shouldValidateValue && productInputValue?.value && productInputValue?.isValidated === undefined) {
            classNames += " not-yet-validated-text-input";
        }
        else if (configuration?.shouldValidateValue && productInputValue?.isValidated) {
            classNames += " validated-text-input";
        }

        return classNames;
    }

    function getInvalidMessage() {
        if (rulesState.inputIsInvalid) {
            return rulesState.brokenRuleMessage;
        }

        if (configuration?.shouldValidateValue && productInputValue?.isValidated === false) {
            return 'Invalid';
        }

        if (configuration?.shouldValidateValue && productInputValue?.showWarning === true) {
            return productInputValue?.warningMessage;
        }

        return "";
    }

    function getReadModeTemplate() {
        return (
            <span className={`text-input-read-only-value ${getConfigurationIsSecureClass(configuration)}`}>
                {productInputValue?.value}
            </span>
        );
    }

    function getValueRenderTemplate() {
        if (isInEditMode) {
            return getEditModeTemplate();
        }
        else {
            return getReadModeTemplate();
        }
    }

    function getClassesForLabel() {
        if (!isInEditMode) {
            return "half-width-text-input";
        }
        return "";
    }

    // If in read only mode, there's no value and its not required, then show nothing
    if (!isInEditMode && !productInputValue?.value && !configuration?.configurationDetail.isRequired) {
        return <></>
    }

    return (
        <span className={"input-text-container " + getClassesForModifiers(configuration?.fieldModifiers)}>

            <Label for={`text-input-${inputId}`} className={`text-input-label ${getClassesForLabel()}`}>
                {inputLabel}
                {configuration?.configurationDetail.priceModification ? ` +(${CurrencyFormatter.format(configuration?.configurationDetail.priceModification)})` : ""}
                {isRequired && isInEditMode ? <RequiredAsterisk></RequiredAsterisk> : <></>}
                {configuration?.shouldValidateValue && productInputValue?.isValidating && <span className="input-text-spinner-container"><Spinner/></span>}
            </Label>
            {getValueRenderTemplate()}
        </span>
    );
}
