import * as z from 'zod';
import {Regexes} from "../utils/Utils";
import {addressSchema, isSavedAddress, toAddress} from "../addresses/AddressSchema";
import {PaymentDetails} from "../components/payment/PaymentDetails";
import {SavedPaymentDetails} from "../components/payment/SavedPaymentDetails";
import {CreditCardPaymentDetails} from "../components/payment/CreditCardPaymentDetails";
import {Address} from "../components/input/address/Address";
import {fallbackRefinement} from "../validation/FallbackRefinement";
import {DateTime} from "luxon";
import exp from "node:constants";

export const PAYMENT_TYPE_CREDIT_CARD = 'CreditCardPaymentDetails';
export const PAYMENT_TYPE_SAVED_PAYMENT = 'SavedPaymentDetails';

const PAYMENT_METHOD_CREDIT_CARD = 1;
const PAYMENT_METHOD_SAVED_PAYMENT = 2;

const expirationDateSchema = z.object({
    month: z.coerce.number()
        .gte(1)
        .lte(12),
    year: z.coerce.number(),
}).superRefine(({month, year}, ctx) => {

    const currentDate = DateTime.now();
    const expiry = DateTime.fromObject({
        month,
        year: Number.parseInt(`20${year}`)
    });
    if (month && year && currentDate > expiry) {
        ctx.addIssue({
            message: 'Credit card is expired',
            code: z.ZodIssueCode.custom
        });
    }
    if (!month || !year) {
        ctx.addIssue({
            message: 'Expiration date is required',
            code: z.ZodIssueCode.custom
        });
    }
});

const creditCardPaymentDetailsSchema = z.object({
    paymentProfileId: z.literal(''),
    useShippingAddress: z.coerce.boolean()
        .default(false),
    savePaymentMethod: z.coerce.boolean()
        .default(true),
    firstName: z.string()
        .min(3),
    lastName: z.string()
        .min(3),
    cardNumber: z.string()
        .regex(Regexes.ccWithFormatting, 'Please enter a valid card number')
        .transform(val => val.replaceAll(' ', '')),
    expirationDate: expirationDateSchema,
    cvc: z.string()
        .min(3)
        .max(4),
    billingAddress: addressSchema
}).strip();

type CreditCardPaymentDetailsSchema = z.infer<typeof creditCardPaymentDetailsSchema>;

const savedPaymentDetailsSchema = z.object({
    paymentProfileId: z.string()
}).passthrough();

type SavedPaymentDetailsSchema = z.infer<typeof savedPaymentDetailsSchema>;

export const paymentDetailsSchema = creditCardPaymentDetailsSchema
    .or(savedPaymentDetailsSchema)
    .superRefine(fallbackRefinement(
        isCreditCardPayment,
        creditCardPaymentDetailsSchema
    ));

export type PaymentDetailsSchema = z.infer<typeof paymentDetailsSchema>;

export function isCreditCardPayment(paymentDetails: PaymentDetailsSchema)
    : paymentDetails is CreditCardPaymentDetailsSchema {
    return paymentDetails.paymentProfileId === '';
}

export function isSavedPayment(paymentDetails: PaymentDetailsSchema)
    : paymentDetails is SavedPaymentDetailsSchema {
    return paymentDetails.paymentProfileId !== '';
}

function toExperationDateString(expiry: z.infer<typeof expirationDateSchema>) {
    return `${expiry.month}/${expiry.year}`
}

export function toPaymentDetails(paymentDetails: PaymentDetailsSchema,
                                 savedPaymentMethod: SavedPaymentDetails | null,
                                 savedBillingAddress: Address | null,
                                 addressVerified = false): PaymentDetails {

    if (isCreditCardPayment(paymentDetails)) {
        if (isSavedAddress(paymentDetails.billingAddress) && !savedBillingAddress) {
            throw Error('Saved billing address is required when using a saved address');
        }
        return {
            ...paymentDetails,
            expirationDateString: toExperationDateString(paymentDetails.expirationDate),
            paymentDetailsType: PAYMENT_TYPE_CREDIT_CARD,
            paymentMethodId: PAYMENT_METHOD_CREDIT_CARD,
            billingAddress: toAddress(paymentDetails.billingAddress, {
                first: paymentDetails.firstName,
                last: paymentDetails.lastName
            }, savedBillingAddress,
                addressVerified)
        } satisfies CreditCardPaymentDetails as PaymentDetails
    } else {
        if (savedPaymentMethod) {
            return savedPaymentMethod as PaymentDetails
        }
        throw Error("Saved payment method is required");
    }
}