import './CartCheckout.css';
import {useTranslation} from "react-i18next";
import {Container,} from "reactstrap";
import {
    useCreateOrderMutation,
    useGetCartProductsPricingQuery,
    useGetCartProductsQuery,
} from "../../../app/apiSlice";
import {
    MediaQueryTypes,
    useAppDispatch,
    useAppSelector,
    useMediaQuery,
} from "../../../app/hooks";
import {selectCartId} from "../CartSlice";
import {CartOverview} from "../overview/CartOverview";
import ScrollToTop from "../../helpers/ScrollToTop";
import {useEffect, useState} from "react";
import {Consumer} from "../../admin/customer/Consumer";
import {Address} from "../../input/address/Address";
import {
    getErrorMessage,

} from "../../../utils/Utils";
import {selectCurrentConsumerUser} from "../../user/login/AuthenticationSlice";
import {SaveOrderVm} from "../../admin/order/SaveOrderVm";
import {useNavigate} from "react-router-dom";
import {CartPricingRequest} from "../CartPricing";
import {skipToken} from "@reduxjs/toolkit/query";
import {OrderCreationResponse} from "../../order/Order";
import {showConfirmationModal, showInformationModal} from "../../modal/ModalSlice";
import {StickySidebar} from '../StickySidebar';
import {setLatestOrderCreationResponse} from "../../order/OrderSlice";
import {AnalyticsTools} from "../../../utils/AnalyticsHelper";
import {CheckoutForm} from "./CheckoutForm";
import {CheckoutSchema} from "./schema";
import {toAddress} from "../../../addresses/AddressSchema";
import {isCreditCardPayment, toPaymentDetails} from "../../../payment/schema";
import {
    getBillingAddressIsVerified,
    getCheckoutIsValid,
    getSelectedSavedBillingAddress,
    getSelectedSavedPaymentDetails,
    getSelectedSavedShippingAddress, getSelectedShippingOption, getShippingAddressIsVerified, getShippingInformation
} from "../../order/CheckoutSlice";

const ValidationMessage = ({isInvalid}: { isInvalid: boolean }) =>
    isInvalid
        ? <div className="cart-checkout-invalid-input">
            <p>There is some missing or incorrect information.</p>
            <p>Please make sure to enter everything that is highlighted.</p>
        </div>
        : <></>;

export const Checkout = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);

    const [cartPricingRequest, setCartPricingRequest] = useState<CartPricingRequest | undefined>();

    const cartId = useAppSelector(selectCartId);
    const selectedSavedShippingAddress = useAppSelector(getSelectedSavedShippingAddress);
    const consumer = useAppSelector(selectCurrentConsumerUser);
    const savedPaymentMethod = useAppSelector(getSelectedSavedPaymentDetails);
    const savedBillingAddress = useAppSelector(getSelectedSavedBillingAddress);
    const shippingOption = useAppSelector(getSelectedShippingOption);
    const shippingInformation = useAppSelector(getShippingInformation);
    const checkoutIsValid = useAppSelector(getCheckoutIsValid);
    const shippingAddressVerified = useAppSelector(getShippingAddressIsVerified);
    const billingAddressVerified = useAppSelector(getBillingAddressIsVerified);
    
    const {data: cartProducts} = useGetCartProductsQuery(cartId);
    const {
        data: cartPricing,
    } = useGetCartProductsPricingQuery(cartPricingRequest ?? skipToken);
    const [createOrder] = useCreateOrderMutation();

    const hasShippingOptionSelected = () => ((shippingOption?.items?.length ?? 0) >= 1);

    useEffect(() => {
        if (cartId) {
            const productShippingServiceSelections = shippingOption?.items.map((i) => {
                return {
                    cartProductId: i.cartProductId,
                    cost: i.price ?? 0,
                    retailCost: i.retailPrice ?? 0
                }
            }) ?? [];
            setCartPricingRequest({
                cartId,
                toAddress: shippingInformation?.shippingAddress
                    ? toAddress(shippingInformation.shippingAddress, {
                        first: shippingInformation.firstName,
                        last: shippingInformation.lastName
                    }, selectedSavedShippingAddress)
                    : undefined
                ,
                productShippingServiceSelections
            });
        } else {
            setCartPricingRequest(undefined);
        }
    }, [cartId, shippingInformation?.shippingAddress, shippingOption]);

    const submitOrder = async (checkout: CheckoutSchema) => {
        // this should be handled by rhf/zod
        // if (
        // 	isPlacingOrder
        // 	|| !validation.allValid()
        // 	|| !validate()
        // 	|| !hasAllOrderRequiredInfo
        // ) return;

        const orderConsumer = consumer ?? {
            id: -1,
            userId: -1,
            siteId: -1,
            firstName: checkout.shippingInformation.firstName,
            lastName: checkout.shippingInformation.lastName,
            email: checkout.emails.email,
            phoneNumber: checkout.shippingInformation.phoneNumber,
        } as Consumer;

        const shippingAddress: Address = toAddress(
            checkout.shippingInformation.shippingAddress,
            {
            first: checkout.shippingInformation.firstName,
            last: checkout.shippingInformation.lastName
        },
            selectedSavedShippingAddress,
            shippingAddressVerified);

        const order: SaveOrderVm = {
            orderId: -1,
            cartId,
            email: checkout.emails.email,
            phoneNumber: checkout.shippingInformation.phoneNumber,
            orderOrigin: 'Customer Site',
            consumer: orderConsumer,
            shippingAddress,
            changeType: 'New Order',
            paymentDetails: toPaymentDetails(checkout.paymentInfo, savedPaymentMethod, savedBillingAddress, billingAddressVerified),
            cartProductShippingEstimateIds: shippingOption?.items?.map(i => i.cartProductShippingEstimateId) ?? [],
            savePayment: (orderConsumer.id === -1) ? false : isCreditCardPayment(checkout.paymentInfo) ? checkout.paymentInfo.savePaymentMethod : undefined
        };

        let orderCreationResponse: OrderCreationResponse | undefined = undefined;
        try {
            orderCreationResponse = await createOrder(order).unwrap();
        } catch (e: any) {
            if (e && e.status === 409) {
                dispatch(showConfirmationModal({
                    title: 'This email address is associated with an account!',
                    showTextContent: false,
                    affirmText: 'Sign in to use this email address',
                    onConfirm: onSignInAndContinueConfirm
                }));
            } else {
                dispatch(showInformationModal({
                    title: 'Failed to Create Order!',
                    text: 'Please contact customer support for assistance at ' + t('phoneNumber.number') + ', ' + t('phoneNumber.hours'),
                }));
            }
        }
        if (orderCreationResponse && cartPricing) {
            AnalyticsTools.recordOrderEvent(orderCreationResponse.orderId, cartPricing, cartProducts ?? []);
            AnalyticsTools.recordConversionEvent(orderCreationResponse.orderId, cartPricing);
            dispatch(setLatestOrderCreationResponse(orderCreationResponse))
            navigate(`/orderConfirmation`);
        }
    };

    const onSignInAndContinueConfirm = () => {
        navigate('/login?redirectTo=/checkout');
    }

    async function onSubmit(order: CheckoutSchema) {
        return await submitOrder(order);
    }

    return (
        <span className="cart-checkout-container">
			<ScrollToTop></ScrollToTop>
			<Container>

				<span className="cart-checkout-header-container">
					<span>
						<h1>{t(`cart.checkout.title`)}</h1>
					</span>
				</span>

				<div className="cart-checkout-content-container">
					<div className="cart-checkout-lane-one">
						<CheckoutForm onSubmit={onSubmit}/>
					</div>

                    {!isMobile && (
                        <span className="cart-checkout-lane-two">
                            <StickySidebar>
                                <>
                                    <CartOverview
                                        cartProducts={cartProducts ?? []}
                                        cartPricing={cartPricing}
                                        shouldShowShipping={hasShippingOptionSelected()}
                                    />
                                    <ValidationMessage
                                        isInvalid={!checkoutIsValid}
                                    />
                                </>
                            </StickySidebar>
                        </span>
                    )}
					
				</div>

			</Container>
		</span>
    )
};
