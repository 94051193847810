import {FormSelect, FormSelectProps} from "../components/forms/FormSelect";
import {FieldValues} from "react-hook-form";
import {useGetStatesQuery} from "../app/apiSlice";

interface FormStateDropdownProps<
    Schema extends FieldValues
> extends Omit<FormSelectProps<Schema>, 'options'> {

}

export function FormStateDropdown<
    Schema extends FieldValues
>(props: FormStateDropdownProps<Schema>) {
    const {data: states = []} = useGetStatesQuery();

    const options = [{
        text: '',
        value: ''
    }, ...states.map(s => ({
        text: s.name,
        value: s.stateCode
    }))];

    return (
        <FormSelect {...props}
                    options={options}/>
    );
}