import {Address} from "../../input/address/Address";
import {PaymentDetails} from "../../payment/PaymentDetails";
import { Consumer } from "../customer/Consumer";
import {ShippingOption} from "../../shipping-options/ShippingOption";
import {CartIdType} from "../../cart/CartSlice";

export interface SaveOrderVm {
  orderId?: number
  cartId?: CartIdType
  orderOrigin?: string
  shippingAddress?: Address
  email: string
  phoneNumber: string
  cartProductShippingEstimateIds: number[]
  paymentDetails?: PaymentDetails
  paymentStatusId?: number
  paymentAmount?: number
  externalPaymentKey1?: number
  savePayment?: boolean
  changeType: string,
  consumer?: Consumer
}

export interface ProductShippingServiceSelection {
  // They might not have this yet, but would be ready to provide shipping details, so
  orderProductId?: number // provide this if you have it
  // provide this as an alternative:
  cartProductId?: number // do not provide this if you have and OrderProductId already
  cost: number
  retailCost: number
  phoneNumber?: string
  email?: string
  trackingNumber?: string
}

export interface UpdateOrderRequest {
  orderId: number,
  orderEditCartId: number,
  shippingAddressId?: number,
  changeType: string,
  paymentDetails?: PaymentDetails,
  newShippingAddress?: Address,
  productShippingServiceSelections?: ProductShippingServiceSelection[]

}

export enum OrderOriginEnum {
  AdminSite = 'Admin Site',
  ConsumerSite = 'Consumer Site',
  MailIn = 'Mail In'
}
